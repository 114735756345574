// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useParams, useNavigate } from 'react-router-dom';

// 
// ** UTILS **
import Api from "../../contexts/Api";
import { getValue, prepareFormData } from "../../contexts/Utils";
import { Button, Title, Container, LinkAnimated } from "../../components/Style/StyledComponents";
import Formfields from "../../components/Formfields";
import Loading from "../../components/Loading";
import { useNotification } from "../../components/UseNotification";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditOffer = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [offer, setOffer] = useState({})
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState({})

  const params = useParams();
  const id = params.offer_id;

  const { notification } = useNotification();

  const navigate = useNavigate();
  // 
  // ─── GET OFFER DETAILS ───────────────────────────────────────
  //
  useEffect(() => {
    const getOffer = async () => {
      const data = await Api({
        endpoint: '/offers',
        query: { offer_id: id },
        method: 'GET'
      });

      if (data.success) setOffer(data.data); setLoading(false)
    }
    if (!Object.keys(offer).length) getOffer()
  }, [offer, id])

  // 
  // ─── HANDLE CHANGE INFOS ───────────────────────────────────────
  //
  const handleChangeInfos = async (e) => {
    e.preventDefault()

    let data = prepareFormData({ formId: 'offer_form' })

    if (data.errors) {
      setErrors({ ...errors, 'offer_form': { ...data.errors } })
    } else {
      if (Object.keys(errors).length) {
        setErrors({});
      }
      //
      // ─── API REQUEST ───────────────────────────────────────
      //
      const manageOffer = await Api({
        data: { fields: data.fields, offer_id: id },
        method: 'PUT',
        endpoint: '/offers',
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageOffer.success) {
        // On update le state user avec les nouvelles informations
        setOffer({ ...offer, ...data.fields })
        return notification({ variant: 'success', message: manageOffer.message })
      } else {
        notification({ variant: 'error', message: manageOffer.message })
      }
    }
  }

  const formFields = [
    { type: "text", component: "text", name: "title", label: 'Titre', width: '100%' },
    { type: "text", component: "text", name: "reference", label: 'Référence', width: '49%' },
    { type: "text", component: "text", name: "sector", label: 'Secteur', width: '49%' },
    { component: "datepicker", name: "time_start", label: 'Date de début', width: '49%' },
    { component: "datepicker", name: "time_end", label: 'Date de fin', width: '49%' },
    { type: "text", component: "textarea", name: "description", label: 'Description', width: window.innerWidth > 800 ? '49%' : '100%' },
    { type: "text", component: "textarea", name: "profile", label: 'Profil', width: window.innerWidth > 800 ? '49%' : '100%' },
    { type: "text", component: "text", name: "cp", label: 'Code postal', width: '49%' },
    { type: "text", component: "text", name: "city", label: 'Ville', width: '49%' },
    { type: "text", component: "text", name: "contract_type", label: 'Type de contrat', width: window.innerWidth > 800 ? '49%' : '100%' },
    { type: "text", component: "text", name: "company", label: 'Compagnie', width: window.innerWidth > 800 ? '49%' : '100%' },
    { type: "text", component: "text", name: "reward", label: 'Récompense', width: '49%' },
    { type: "text", component: "text", name: "experience_min", label: 'Expérience minimum', width: '49%' },
    { type: "text", component: "text", name: "salary_min", label: 'Salaire minimum', width: '49%' },
    { type: "text", component: "text", name: "salary_max", label: 'Salaire maximum', width: '49%' },
    { type: "text", component: "textarea", name: "salary_additional", label: 'Informations supplémentaires', width: window.innerWidth > 800 ? '49%' : '100%' },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Loading loading={loading}>
    <Container>
      <S.Back>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M234.8 36.25c3.438 3.141 5.156 7.438 5.156 11.75c0 3.891-1.406 7.781-4.25 10.86L53.77 256l181.1 197.1c6 6.5 5.625 16.64-.9062 22.61c-6.5 6-16.59 5.594-22.59-.8906l-192-208c-5.688-6.156-5.688-15.56 0-21.72l192-208C218.2 30.66 228.3 30.25 234.8 36.25z" /></svg>
        <LinkAnimated onClick={() => navigate(-1)}>
          Retour
        </LinkAnimated>
      </S.Back>
      <S.Form id='offer_form'>
        <S.Flex>
          <Title>Modifier l'offre</Title>
          <Formfields field={{ type: "text", component: "checkbox", name: "display", checked: offer['display'] ? true : false, label: 'Offre visible', width: '49%', ...{ error: getValue(errors, ["display", 'message']) } }} />
        </S.Flex>
        {formFields.map(field => {
          return <Formfields key={field.name} field={{ ...field, ...{ default: offer[field.name] }, ...{ error: getValue(errors, [field.name, 'message']) } }} />
        })}
        <Button as="input" type="submit" value="Enregistrer" onClick={handleChangeInfos}></Button>
      </S.Form>
    </Container>
  </Loading>
};

export default EditOffer;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Back = styled.div`
display: flex;
align-items: center;
gap: 5px;

& svg {
  width: 13px;
  height: 15px;
  fill: ${({ theme }) => theme.secondary};
}

& a {
  font-size: 14px;
  margin: 0;
  padding-bottom: 0;
  color: white;
}
`
S.Flex = styled.div`
display: flex;
gap: 20px;
width: 100%;
align-items: baseline;
justify-content: space-between;
flex-direction: column;

  &.formfield_container {
    margin-bottom: 0;
  }

  & input[type="checkbox"] {
    cursor: pointer;
  }
`

S.Form = styled.form`
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & h2 {
    width: 100%;
  color: ${({ theme }) => theme.textPrimary};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 30px;
  ${({ theme }) => theme.width > 800 && 'width: 100%'};
 }

 & input {
    margin: 0 auto;
    ${({ theme }) => theme.width > 800 && 'height: 40px'};
 }

  & .formfield_container {
    margin-bottom: 20px;

    & div {
      padding-right: 0;

      &:after {
        border-top: 1px solid black;
        border-right: 1px solid black;
        margin-right: 10px;
      }

      & select {
      background: ${({ theme }) => theme.secondary};
      border-radius: 5px;
      color: black;
      }
    }
  }
`