
import styled from 'styled-components';

//
// ─── DUMMY DATA ───────────────────────────────────────
//

const data = [
  {
    title: "Exercitation. Occaecat in. Nisi.",
    content: "Ullamco labore aliqua laborum anim culpa in consequat pariatur cupidatat amet cillum. Eiusmod labore aute officia culpa nostrud culpa cillum nostrud tempor nulla id reprehenderit. Eiusmod laboris id pariatur adipisicing reprehenderit do culpa culpa ut velit nostrud pariatur consectetur nostrud. Elit magna eu do irure deserunt nulla deserunt Lorem sint enim pariatur. Lorem amet esse proident sit Lorem officia quis. Duis adipisicing nulla aliquip esse consectetur deserunt cupidatat consectetur nisi quis. Cupidatat culpa tempor mollit culpa deserunt occaecat amet amet ad do."
  },
  {
    title: "Exercitation. Occaecat in. Nisi.",
    content: "Ullamco labore aliqua laborum anim culpa in consequat pariatur cupidatat amet cillum. Eiusmod labore aute officia culpa nostrud culpa cillum nostrud tempor nulla id reprehenderit. Eiusmod laboris id pariatur adipisicing reprehenderit do culpa culpa ut velit nostrud pariatur consectetur nostrud. Elit magna eu do irure deserunt nulla deserunt Lorem sint enim pariatur. Lorem amet esse proident sit Lorem officia quis. Duis adipisicing nulla aliquip esse consectetur deserunt cupidatat consectetur nisi quis. Cupidatat culpa tempor mollit culpa deserunt occaecat amet amet ad do."
  },
  {
    title: "Exercitation. Occaecat in. Nisi ?",
    content: "Ullamco labore aliqua laborum anim culpa in consequat pariatur cupidatat amet cillum. Eiusmod labore aute officia culpa nostrud culpa cillum nostrud tempor nulla id reprehenderit. Eiusmod laboris id pariatur adipisicing reprehenderit do culpa culpa ut velit nostrud pariatur consectetur nostrud. Elit magna eu do irure deserunt nulla deserunt Lorem sint enim pariatur. Lorem amet esse proident sit Lorem officia quis. Duis adipisicing nulla aliquip esse consectetur deserunt cupidatat consectetur nisi quis. Cupidatat culpa tempor mollit culpa deserunt occaecat amet amet ad do."
  },
  {
    title: "Exercitation. Occaecat in. Nisi.",
    content: "Ullamco labore aliqua laborum anim culpa in consequat pariatur cupidatat amet cillum. Eiusmod labore aute officia culpa nostrud culpa cillum nostrud tempor nulla id reprehenderit. Eiusmod laboris id pariatur adipisicing reprehenderit do culpa culpa ut velit nostrud pariatur consectetur nostrud. Elit magna eu do irure deserunt nulla deserunt Lorem sint enim pariatur. Lorem amet esse proident sit Lorem officia quis. Duis adipisicing nulla aliquip esse consectetur deserunt cupidatat consectetur nisi quis. Cupidatat culpa tempor mollit culpa deserunt occaecat amet amet ad do."
  },
  {
    title: "Exercitation. Occaecat in. Nisi ?",
    content: "Ullamco labore aliqua laborum anim culpa in consequat pariatur cupidatat amet cillum. Eiusmod labore aute officia culpa nostrud culpa cillum nostrud tempor nulla id reprehenderit. Eiusmod laboris id pariatur adipisicing reprehenderit do culpa culpa ut velit nostrud pariatur consectetur nostrud. Elit magna eu do irure deserunt nulla deserunt Lorem sint enim pariatur. Lorem amet esse proident sit Lorem officia quis. Duis adipisicing nulla aliquip esse consectetur deserunt cupidatat consectetur nisi quis. Cupidatat culpa tempor mollit culpa deserunt occaecat amet amet ad do."
  },
]

const LegalNotice = () => {
  return <S.Container>
    <S.InnerContainer>
      <S.Title>Mentions légales</S.Title>
      <S.LegalsContainer>
        {data.map(({ title, content }, index) => {
          return <div key={index}>
            <h3>{index + 1}. {title}</h3>
            <p>{content}</p>
          </div>
        })}
      </S.LegalsContainer>
    </S.InnerContainer>
  </S.Container>
}

export default LegalNotice;

const S = {};
//
// ─── CONTAINERS ───────────────────────────────────────
//
S.Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
`

S.InnerContainer = styled.div`
  padding: 32px 20px;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;

  @media (min-width: 640px) {
    padding: 32px 40px;
  }

  @media (min-width: 980px) {
    padding: 32px 80px;
  }
`
//
// ─── TITLE ───────────────────────────────────────
//
S.Title = styled.h1`
  margin-bottom: 8px;
  color: white;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.2;

  @media (min-width: 640px) {
    font-size: 38px;
    text-align: center;
  }

  @media (min-width: 980px) {
    font-size: 48px;
  }
`

//
// ─── QUESTIONS ───────────────────────────────────────
//

S.LegalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 840px;
  margin: 0 auto;
  box-sizing: border-box;
  color: white;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 980px) {
    min-width: 640px;
  }
`

