import { useEffect } from "react";

// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import styled from "styled-components/macro";
//
// ** PNG **
import share from '../assets/static/share.png'
import Api from "../contexts/Api";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Share = (opt) => {
  const url = opt.url || '';
  const sponsor = opt.sponsor || '';
  const icon = opt.icon || false;
  const text = opt.text || 'Je pense que cette offre peut vous intéresser !';

  const shareData = {
    title: 'Satis Cooptation',
    text,
    url: process.env.REACT_APP_URL + url + sponsor,
  };

  // A custom "onShare" handler for AddToAny
  const my_addtoany_onshare = (value) => {
    async function fetchData() {
      await Api({
        endpoint: '/share_credits',
        data: { service: value.service },
        method: 'PUT'
      });
    }
    fetchData()
  }


  useEffect(() => {
    if (!document.querySelector('#addtoany')) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://static.addtoany.com/menu/page.js';
      script.id = 'addtoany';
      script.onload = () => {
        // window.a2a_config.num_services = 10;
        // window.a2a_config.show_title = 1;
        window.a2a_config.callbacks = window.a2a_config.callbacks || [];
        window.a2a_config.callbacks.push({
          share: my_addtoany_onshare,
        });
        window.a2a_config.exclude_services = ['pinterest', 'telegram', 'reddit', 'pocket', 'tumblr', 'google_classroom', 'amazon_wish_list', 'aol_mail', 'balatarin', 'bibsonomy', 'bitty_browser', 'blogger', 'blogmarks', 'bookmarks_fr', 'box_net', 'buffer', 'diary_ru', 'diaspora', 'digg', 'diigo', 'douban', 'draugiem', 'evernote', 'fark', 'flipboard', 'folkd', 'hacker_news', 'hatena', 'houzz', 'instapaper', 'kakao', 'kindle_it', 'known', 'line', 'livejournal', 'mail_ru', 'mastodon', 'mendeley', 'meneame', 'mewe', 'microsoft_teams', 'mixi', 'myspace', 'odnoklassniki', 'outlook_com', 'papaly', 'pinboard', 'plurk', 'printfriendly', 'pusha', 'qzone', 'rediff', 'refind', 'sina_weibo', 'sitejot', 'skype', 'slashdot', 'stocktwits', 'svejo', 'symbaloo_bookmarks', 'threema', 'trello', 'twiddla', 'viber', 'vk', 'wechat', 'wordpress', 'wykop', 'xing', 'typepad_post', 'yahoo_mail', 'yummly', 'print', 'google_translate', 'raindrop_io', 'twitter',];
      }
      document.body.appendChild(script);
    } else if (window.a2a) {
      window.a2a.init_all()
    }
  }, []);

  return <S.Share className="share-button">
    <div className="a2a_kit a2a_kit_size_32 a2a_default_style" data-a2a-url={shareData.url} data-a2a-title={shareData.title}>
      <a className="a2a_dd" href="https://www.addtoany.com/share">
        {icon ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" /></svg> : 'Partager'}
      </a>
    </div>
  </S.Share>;

};

export default Share;

const S = {}

S.Share = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: ${({ theme }) => theme.primary};
width: 40px;
height: 40px;
border-radius: 50%;
cursor: pointer;

&:hover {
  background: ${({ theme }) => theme.hoverButton};
}

& svg {
  fill: ${({ theme }) => theme.secondary};
  width: 20px;
  height: 20px;
}
`