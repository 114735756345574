// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Cookies from 'universal-cookie';
// 
// ** UTILS **
import Api from "../contexts/Api";
import { formatDate } from "../contexts/Utils";
// ** COMPONENTS **
import { Container, Title, EmptyContainer, Offer, LinkAnimated } from "../components/Style/StyledComponents";
import Stepper from '../components/Stepper';
import Loading from "../components/Loading";
import { breakpoints } from "../components/Style/StyledBreakpoints";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Applications = () => {
  const [offers, setOffers] = useState([])
  const [empty, setEmpty] = useState(false)
  const [loading, setLoading] = useState(true)
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // 
  // ─── GET OFFERS' USERS TRACKING ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsersOffers = async () => {
      const data = await Api({
        endpoint: '/offers_applied',
        query: { fields: ['offers.title', 'offers_applied.offer_id', 'offers_applied.time_create', 'offers_applied.status'], single_user: userData.user_id, join: true },
        method: 'GET'
      });

      if (data && data.success) setOffers(data.data)
      else setEmpty(true)
      setLoading(false)
    }
    if (!offers.length) getUsersOffers()
  }, [offers])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Container>
    <Title>Mes postulations</Title>

    <Loading loading={loading}>
      {offers.length && <S.Labels>
        {Object.values(window._DATA.types.user_status).map((status, index) => {
          return <span key={index}>{status.label}</span>
        })}
      </S.Labels>}
      {offers.length && offers.map((offer) => {
        return <S.Offer key={offer.offer_id}>
          <S.FlexContainer>
            <S.UserInfos>
              <span>{offer.title}</span>
              <span>Postulé le {formatDate({ time: offer.time_create, display: 'date' })}</span>
            </S.UserInfos>
            <S.Stepper>
              <Stepper status={offer.status} />
            </S.Stepper>

          </S.FlexContainer>
          <LinkAnimated to={`/offer-detail/${offer.offer_id}`} className='seeMore'>
            Voir l'offre
          </LinkAnimated>
        </S.Offer>
      })}
      {empty && <EmptyContainer>
        <h1>
          Oops ...
        </h1>
        Pas de postulations pour le moment.
      </EmptyContainer>}
    </Loading>
  </Container>
};

export default Applications;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Labels = styled.div`
display: flex;
justify-content: flex-end;
color: ${({ theme }) => theme.textSecondary};
font-size: 10px;
gap: 20px;
padding-right: 10px;
`

S.Offer = styled(Offer)`
${breakpoints("max-width", "", [
  { 800: "100%" },
], "min-width")};
& a {
  font-size: 10px!important;
}
`

S.FlexContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: 10px;
`

S.UserInfos = styled.div`
display: flex;
flex-direction: column;
font-size: 15px;
font-weight: 500;

& span:last-child {
  color: ${({ theme }) => theme.textPrimary};
  font-size: 10px;
}
`

S.Stepper = styled.div`
 & .barStepper {
  ${breakpoints("background", "", [
  { 800: "black" },
], "min-width")};
 }
`