import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import Cookies from 'universal-cookie';
import Api from '../contexts/Api';
import { getValue, prepareFormData } from '../contexts/Utils';
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';
import { LinkAnimated, BetterButton } from '../components/Style/StyledComponents';
import { useState } from 'react';

import { breakpoints } from '../components/Style/StyledBreakpoints';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Login = () => {
  let navigate = useNavigate();
  const { notification } = useNotification();
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState({})

  // TRACKING SPONSOR CODE + URL OFFRE PARTAGEE
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sponsorCode = searchParams.get('sponsor_code');
  const url = searchParams.get('url');

  // HANDLE IF INPUTS ARE FILLED
  const handleFields = (opt) => {
    let key = Object.keys(opt)[0]
    let value = Object.values(opt)

    setFields(prev => ({ ...prev, [key]: value[0].length > 0 ? true : false }))
  }

  // 
  // ─── FORMFIELDS ───────────────────────────────────────
  //
  const formFields = [
    {
      type: "email", component: "text", name: "email", label: "Email", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>`, required: true, noStar: true, error: getValue(errors, ['email', 'message']), handleSearch: (e) => handleFields(e)
    },
    {
      type: "password", component: "text", name: "password", label: "Mot de passe", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z"/></svg>`, required: true, noStar: true, error: getValue(errors, ['password', 'message']), handleSearch: (e) => handleFields(e)
    }
  ]

  // 
  // ─── HANDLE USER LOGIN ───────────────────────────────────────
  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = prepareFormData({ formId: 'loginForm' });
    if (data.errors) return setErrors(data.errors)
    else setErrors({});

    const logUser = await Api({
      data,
      endpoint: '/auth/login'
    })

    // 
    // ─── ON SUCCESS WE USE REDUX TO LOG USER ───────────────────────────────────────
    //
    if (logUser.success && logUser.access_token) {
      cookies.set(process.env.REACT_APP_USER_COOKIE, logUser.data, {
        expires: new Date(Date.now() + 604800000), // time until expiration
        secure: false, // true if using https
      })
      // NAVIGATE CAUSES COOKIES TO BUG FOR THE ROUTER
      //return navigate(sponsorCode ? `${url}?sponsor_code=${sponsorCode}` : '/');
      return window.location = sponsorCode ? `${url}?sponsor_code=${sponsorCode}` : '/';
    } else {
      notification({ variant: 'error', message: logUser.message })
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return userData ? <Navigate to={sponsorCode ? `${url}?sponsor_code=${sponsorCode}` : '/'} /> : <>
    <S.TitleContainer>
      <S.SvgContainer>
        <svg className='svgs' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 0a128 128 0 1 0 0 256A128 128 0 1 0 224 0zM157.3 327.1c-3.2-4.3-8.4-6.7-13.7-6.2C62.9 329.7 0 398.2 0 481.3c0 17 13.8 30.7 30.7 30.7H417.3c17 0 30.7-13.8 30.7-30.7c0-83.1-62.9-151.5-143.7-160.3c-5.3-.6-10.5 1.9-13.7 6.2l-53.9 71.8c-6.4 8.5-19.2 8.5-25.6 0l-53.9-71.8z" /></svg>
        <svg className='svgs' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128c0-11.4-2.4-22.2-6.7-32H296c-20.5 0-38.7-9.6-50.4-24.5C231.9 95.7 205.8 112 176 112H145.6c-1 5.2-1.6 10.5-1.6 16v16c0 44.2 35.8 80 80 80s80-35.8 80-80V128zM96 128C96 57.3 153.3 0 224 0s128 57.3 128 128v11c0 33.9 13.5 66.5 37.5 90.5l3.9 3.9c4.2 4.2 6.6 10 6.6 16c0 12.5-10.1 22.6-22.6 22.6H224 70.6C58.1 272 48 261.9 48 249.4c0-6 2.4-11.8 6.6-16l3.9-3.9c24-24 37.5-56.6 37.5-90.5V128zm61.3 199.1l53.9 71.8c6.4 8.5 19.2 8.5 25.6 0l53.9-71.8c3.2-4.3 8.4-6.7 13.7-6.2C385.1 329.7 448 398.2 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3C0 398.2 62.9 329.7 143.7 321c5.3-.6 10.5 1.9 13.7 6.2z" /></svg>
        <svg className='svgs' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 167C64 74.8 135.6 0 224 0s160 74.8 160 167c0 101-106.6 185-139.2 208.3c-6.1 4.3-13.4 6.5-20.8 6.5s-14.7-2.1-20.8-6.5C170.6 352 64 268.1 64 167zm244.6 9c-37.9 0-68.6 30.7-68.6 68.6c0 6.3 5.1 11.4 11.4 11.4h16c37.9 0 68.6-30.7 68.6-68.6c0-6.3-5.1-11.4-11.4-11.4h-16zM208 244.6c0-37.9-30.7-68.6-68.6-68.6h-16c-6.3 0-11.4 5.1-11.4 11.4c0 37.9 30.7 68.6 68.6 68.6h16c6.3 0 11.4-5.1 11.4-11.4zM0 486.4C0 415 55.7 356.6 126.1 352.3c23.8 23.2 46.1 40.2 58.5 49.1c12 8.6 25.9 12.4 39.4 12.4s27.4-3.8 39.4-12.4c12.4-8.8 34.7-25.8 58.5-49.1C392.3 356.6 448 415 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4z" /></svg>
        <svg className='svgs' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 32C135.6 32 64 103.6 64 192v16c0 8.8-7.2 16-16 16s-16-7.2-16-16V192C32 86 118 0 224 0S416 86 416 192v16c0 61.9-50.1 112-112 112H240 224 208c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32h32c44.2 0 80-35.8 80-80V192c0-88.4-71.6-160-160-160zM96 192c0-70.7 57.3-128 128-128s128 57.3 128 128c0 13.9-2.2 27.3-6.3 39.8C337.4 246.3 321.8 256 304 256h-8.6c-11.1-19.1-31.7-32-55.4-32H208c-35.3 0-64 28.7-64 64c0 1.4 0 2.7 .1 4C114.8 268.6 96 232.5 96 192zM224 352h16 64 9.6C387.8 352 448 412.2 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4C0 412.2 60.2 352 134.4 352H208h16z" /></svg>
        <svg className='svgs' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 0c8.8 0 16 7.2 16 16V64h64c35.3 0 64 28.7 64 64V256c0 35.3-28.7 64-64 64H144c-35.3 0-64-28.7-64-64V128c0-35.3 28.7-64 64-64h64V16c0-8.8 7.2-16 16-16zM16 144c0-8.8 7.2-16 16-16s16 7.2 16 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V144zm400-16c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V144c0-8.8 7.2-16 16-16zM160 192a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm160-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM160 240a16 16 0 1 0 0 32 16 16 0 1 0 0-32zm48 16a16 16 0 1 0 32 0 16 16 0 1 0 -32 0zm80-16a16 16 0 1 0 0 32 16 16 0 1 0 0-32zM96 352H352c53 0 96 43 96 96v32c0 17.7-14.3 32-32 32H352V448c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32v64H32c-17.7 0-32-14.3-32-32V448c0-53 43-96 96-96zm80 112v48H144V464c0-8.8 7.2-16 16-16s16 7.2 16 16zm48-16c8.8 0 16 7.2 16 16v48H208V464c0-8.8 7.2-16 16-16zm80 16v48H272V464c0-8.8 7.2-16 16-16s16 7.2 16 16z" /></svg>
      </S.SvgContainer>
      <h1>Connectez-vous</h1>
    </S.TitleContainer>
    <S.Form id='loginForm'>
      {formFields.map((el) => (<Formfields key={el.name} field={el} />))}
      <S.ButtonContainer>
        <BetterButton filled={fields.email && fields.password ? true : false} as="input" type="submit" value="Se connecter" onClick={handleSubmit}></BetterButton>
      </S.ButtonContainer>
      <S.Inscription>Pas encore de compte ? <LinkAnimated to={sponsorCode ? `/inscription?url=${url}&sponsor_code=${sponsorCode}` : '/inscription'}>Se créer un compte</LinkAnimated></S.Inscription>
      <S.LinkAnimated to="/mot-de-passe-oublie">J'ai oublié mon mot de passe</S.LinkAnimated>
    </S.Form>
  </>
};

export default Login;

// 
// ─── STYLE DEFINIITION ───────────────────────────────────────
//
const S = {}

S.Form = styled.form`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  & .formfield_container {

    & input {
      background: #252525;
      border-radius: 0px;
      color: ${({ theme }) => theme.secondary};
      opacity: 0.7;
      border-radius: 0px 2px 2px 0px;
      &:focus, :not(&[value=""]) {
        & + label {
          transform: translateY(-40px);
        }
      }
    }

    & .password_field{
      & svg {
        fill: ${({ theme }) => theme.secondary};
        opacity: 0.7;
      }
    }
  }
`

S.TitleContainer = styled.div`
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > h1 {
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    color: white
  }
`

S.SvgContainer = styled.div`
position: relative;
width: 50px;
height: 50px;
& > svg {
    top: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    fill: ${({ theme }) => theme.secondary};
    margin-bottom: 30px;
    animation: icon-animation 6s infinite;
    transform: scale(0);
    transform-origin: center;

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 1.2s;
    }
    &:nth-child(3) {
      animation-delay: 2.4s;
    }
    &:nth-child(4) {
      animation-delay: 3.6s;
    }
    &:nth-child(5) {
      animation-delay: 4.8s;
    }
  }

  @keyframes icon-animation {
    0% {transform: scale(0)}
    10% {transform: scale(1)}
    20% {transform: scale(1)}
    30% {transform: scale(0)}
  }

`

S.ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;

  & button {
    font-weight: 300;
  }
`

S.LinkAnimated = styled(LinkAnimated)`
margin: 0 0 0 auto;
${breakpoints("margin", "", [
  { 600: "0 auto" },
])};
`

S.Inscription = styled.div`
  color: ${({ theme }) => theme.secondary};
  font-size: 12px;
  margin: 0;
  ${breakpoints("margin", "", [
  { 600: "0 auto" },
])};
  font-weight: 400;
`