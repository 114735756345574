// 
// ─── IMPORT ───────────────────────────────────────
// 
// ** COMPONENTS **
import { Spinner } from "./Style/StyledComponents";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Loading = ({ loading, children }) => {

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return loading ? <Spinner /> : children
};

export default Loading;