// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { BackButton, Button } from '../components/Style/StyledComponents';
import { useNavigate } from 'react-router-dom';
import Phone1 from '../assets/static/Phone1.png'
import Phone2 from '../assets/static/Phone2.png'
import Phone3 from '../assets/static/Phone3.png'
import Background1 from '../assets/static/Background1.jpg'
import Background2 from '../assets/static/Background2.jpg'
import Background3 from '../assets/static/Background3.jpg'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const HomLogedOut = () => {
  let navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(1);

  const handleSlide = (opt) => {
    if (opt.type === 'increment' && activeIndex < 3) {
      setActiveIndex(activeIndex + 1)
    }
    if (opt.type === 'decrement' && activeIndex > 1) {
      setActiveIndex(activeIndex - 1)
    }
  }

  const slides = {
    Phone1: { id: 1, img: Phone1 },
    Phone2: { id: 2, img: Phone2 },
    Phone3: { id: 3, img: Phone3 },
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <S.Background><img src={activeIndex === 1 ? Background1 : activeIndex === 2 ? Background2 : Background3} alt='background' /></S.Background>
    <S.Container>
      {activeIndex === 1 && <S.TitleContainer>
        <span>Parrainez</span>
        <div>
          <div><span></span><span></span></div>
          <span>EN 1 CLIC</span>
        </div>
      </S.TitleContainer>}
      {activeIndex === 2 && <S.TitleContainer>
        <span>Suivez vos</span>
        <div>
          <div><span></span><span></span></div>
          <span>PARRAINAGES</span>
        </div>
      </S.TitleContainer>}
      {activeIndex === 3 && <S.TitleContainer>
        <span>Soyez</span>
        <div>
          <div><span></span><span></span></div>
          <span>RÉMUNÉRÉ</span>
        </div>
      </S.TitleContainer>}
      <S.SliderContainer>
        <S.SliderCard id={1} activeIndex={activeIndex}>
          <img src={Phone1} alt="" />
        </S.SliderCard>
        <S.SliderCard id={2} activeIndex={activeIndex}>
          <img src={Phone2} alt="" />
        </S.SliderCard>
        <S.SliderCard id={3} activeIndex={activeIndex}>
          <img src={Phone3} alt="" />
        </S.SliderCard>
        {/* LEFT */}
        <S.LeftArrow onClick={() => handleSlide({ type: 'decrement' })}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
        </S.LeftArrow>
        {/* RIGTH */}
        <S.RightArrow onClick={() => handleSlide({ type: 'increment' })}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
        </S.RightArrow>
      </S.SliderContainer>

      <S.SliderDots>
        <div id={1} className={activeIndex === 1 ? 'dot-active' : ''}></div>
        <div id={2} className={activeIndex === 2 ? 'dot-active' : ''}></div>
        <div id={3} className={activeIndex === 3 ? 'dot-active' : ''}></div>
      </S.SliderDots>
      <S.ButtonContainer>
        <BackButton onClick={() => { navigate('/inscription') }}>Inscription</BackButton>
        <Button onClick={() => { navigate('/connexion') }}>Connexion</Button>
      </S.ButtonContainer>
    </S.Container>
  </>
};

export default HomLogedOut;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  @media screen and (min-width:1600px){
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
`

S.SliderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  padding-bottom: 40;
  width:240px;
  margin:auto;
  padding-bottom: 20px;
  position: relative;
`

S.SliderCard = styled.div`
  scroll-snap-align: center;
  flex: 0 0 auto;
  max-width: 240px;
  position: relative;
  display: ${props => props.id === props.activeIndex ? 'block' : 'none'};

  & img{
    width:100%;
  }
`
S.LeftArrow = styled.div`
position: absolute;
left: -30px;
top: 50%;
transform: translateY(-50%);
cursor: pointer;
& svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.primary}
  }
`

S.RightArrow = styled.div`
position: absolute;
right: -30px;
top: 50%;
transform: translateY(-50%);
cursor: pointer;
& svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.primary}
  }
`

S.SliderDots = styled.div`
  display:flex;
  justify-content:center;
  cursor: pointer;

  div{
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background: #626262;
  }
  .dot-active{
    background: ${({ theme }) => theme.primary};
    width:30px;
    border-radius:20px;
  }
`

S.TitleContainer = styled.div`
  font-weight: bold;
  margin:30px auto 30px;
  width: fit-content;
  &>span{
    color:white;
    font-size: 1.2em;
  }
  &>div>span{
    color: ${({ theme }) => theme.primary};
    font-size:2em;
  }
  &>div{
    display:flex;
  }
  &>div>div{
    display:flex;
    flex-direction: column;
    width:35px;
    margin-right: 10px;
    gap:6px;
    align-items: flex-end;
    justify-content: center;
    & span{
      width: 100%;
      height:4px;
      background: white;
      border-radius: 4px;
      &:nth-child(2){
        width: 20px;
        background:${({ theme }) => theme.primary};
      }
    }
  }
`

S.ButtonContainer = styled.div`
  display: flex;
  gap:20px;
  margin: 40px 0;
`

S.Background = styled.div`
  position: fixed;
  z-index: -1;
  inset: 0;
  display: flex;
  background: black;
  justify-content: center;

  & > img {
    width: 600px;
    height: 600px;
    object-fit: cover;
    margin:auto;
  }

  &:after {
    inset: 0px;
    content: "";
    position: absolute;
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%); */
  }
`