// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import styled from "styled-components/macro";
// 
// ** UTILS **
import AccordionMobile from "./AccordionMobile";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListMobile = ({ sponsored, offers, offerEdit, edit }) => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.UsersContainer>
    {sponsored && sponsored.map((user) => {
      return <AccordionMobile key={user.user_id} offerEdit={offerEdit} user={user} offers={offers} edit={edit} />
    }
    )}
  </S.UsersContainer>
};

export default ListMobile;

const S = {}
S.Container = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
box-sizing: border-box;
padding: 0 20px;

& h1 {
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.textSecondary};
  margin: 0;
}
`

S.UsersContainer = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
`