// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import styled from "styled-components/macro";
//
// ** UTILS **
import { ModalWrapper, ModalContent, ModalOverlay, Button, Close, Title } from "../../Style/StyledComponents";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const AlertModal = (opt) => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <ModalWrapper>
    <ModalOverlay onClick={opt.onClose} />
    <S.ModalContent>
      <Close onClick={opt.onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z" /></svg>
      </Close>
      <S.Title>
        Etes-vous sûr ?
      </S.Title>
      <Button as="input" type="submit" value="Sûr !" onClick={opt.handleConfirm}></Button>
    </S.ModalContent>
  </ModalWrapper>
};

export default AlertModal;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.ModalContent = styled(ModalContent)`
text-align: center;

& input {
  margin: 0 auto;
}
`

S.Title = styled(Title)`
color: ${({ theme }) => theme.third};
`
