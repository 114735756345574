/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { getValue, formatDate, debounce } from '../contexts/Utils';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import ButtonGroup from "@mui/material/ButtonGroup";
import Autocomplete from '@mui/material/Autocomplete';
import SvgComponent from "./Style/SvgComponent";
import Api from "../contexts/Api";

// 
// ─── TEXTFIELD ───────────────────────────────────────
//
export const Text = (props) => {
  let defaultValue = getValue(props, ['default']) || props.value || ''
  const [value, setValue] = useState(defaultValue)

  const handleChange = (e) => {
    setValue(e.target.value);
    if (props.handleChange) {
      props.handleChange(e.target.value)
    }
    if (props.handleSearch) {
      props.handleSearch({ [e.target.name]: e.target.value })
    }
  };

  // RESET VALUE INPUT ON RESET
  if (props.reset && value) {
    defaultValue = ''
    setValue(defaultValue)
  }

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const handlePassword = (e) => {
    e.currentTarget.classList.toggle('active');
    const isActive = e.currentTarget.classList.contains('active');
    document.querySelector(`#${props.name}`).setAttribute('type', isActive ? "text" : "password")
  }
  // multiline ???
  return <S.TextField error={props.error ? true : false} password={props.type === 'password' ? 1 : 0} icon={props.icon ? 1 : 0}>
    {props.label && <label htmlFor={props.name}>{props.label}{props.required && !props.noStar && '*'}</label>}
    <S.Flex>
      {props.icon && <SvgComponent svgCode={props.icon} />}
      <input
        value={value}
        onChange={handleChange}
        type={props.type}
        name={props.name}
        id={props.name}
        placeholder={props.placeholder}
        required={props.required}
        data-default={defaultValue}
        className={props.className}
      />
      {props.type === 'password' && <S.PasswordEye onClick={handlePassword} className='password_field'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" /></svg>
      </S.PasswordEye>}
    </S.Flex>

    {props.error && <span>{props.error}</span>}
  </S.TextField>
}

/// 
// ─── TEXTAREA ───────────────────────────────────────
//
export const Textarea = (props) => {
  const defaultValue = getValue(props, ['default']) || props.value || ''
  const [value, setValue] = useState(defaultValue)

  const handleChange = (e) => {
    setValue(e.target.value);
    if (props.handleChange) {
      props.handleChange(e.target.value)
    }
  };

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return <S.Textarea error={props.error ? true : false}>
    {props.label && <label htmlFor={props.name}>{props.label}{props.required && !props.noStar && '*'}</label>}
    <textarea
      value={value}
      onChange={handleChange}
      type={props.type}
      name={props.name}
      id={props.name}
      placeholder={props.placeholder}
      required={props.required}
    />
    {props.error && <span>{props.error}</span>}
  </S.Textarea>
}


// 
// ─── SWITCH ───────────────────────────────────────
//
export const SwitchField = (opt) => {

  const field = opt.field
  const defaultValue = getValue(field, ['default']) !== undefined ? getValue(field, ['default']) : field.value || 0
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [selected, setSelected] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue !== selected) {
      setSelected(defaultValue)
    }
  }, [defaultValue])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  return (
    <>
      <FormControlLabel
        control={
          <Switch checked={selected ? true : false} value={selected} onChange={() => {
            let value = selected === 0 ? 1 : 0
            setSelected(value)
            if (field.handleChange) {
              field.handleChange(value)
            }
          }} name={field.name} />
        }
        label={field.label || ''}
      />
    </>
  )
}

// 
// ─── RADIO ───────────────────────────────────────
//
export const RadioField = (props) => {
  const defaultValue = getValue(props, ['default']) || 1;
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value);
    setValue(newValue);
    if (props.handleChange) props.handleChange(newValue);
  };

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  return <S.RadioField error={props.error ? true : false} className='radio_field'>
    {props.label && <span>{props.label}{props.required && !props.noStar && '*'}</span>}
    <div className="container" direction={props.direction ? props.direction : 'row'}>
      {props.value.map((radio) => (
        <S.Radio key={radio.value} htmlFor={radio.value}>
          <input type='radio' checked={value === radio.value} value={radio.value} id={radio.value} name={props.name} onChange={handleChange} onClick={radio.onClick} />
          {radio.img ? <S.ImageContainer className={value === radio.value ? 'active' : ''}><img src={radio.img} className='imgSelect' alt="selectImg" /></S.ImageContainer> : <span className="checkmark"></span>}
        </S.Radio>
      ))}
    </div>
    {props.value.map((radio) => (
      <S.LabelRadio key={radio.value}>{value === radio.value ? radio.label : ''}</S.LabelRadio>
    ))}
    {props.error && <span>{props.error}</span>}
  </S.RadioField>
}

// 
// ─── SELECT ───────────────────────────────────────
//
export const SelectField = (props) => {
  const [missingData, setMissingData] = useState(false);
  const fromSearch = props.fromSearch || false;
  let defaultValue, defaultProps = getValue(props, ['default']);
  if (defaultProps !== undefined) defaultValue = defaultProps
  else defaultValue = -1;

  defaultValue = typeof defaultValue === 'string' ? parseInt(defaultValue) : defaultValue;
  const [select, selectValue] = useState(defaultValue);

  const handleChange = (event) => {
    if (fromSearch && !props.city) return setMissingData(true);
    selectValue(event.target.value);
    if (props.onChange) props.onChange(event.target.value)
    if (props.handleSearch) props.handleSearch({ [event.target.name]: event.target.value })
  };

  useEffect(() => {
    if (defaultValue !== undefined && select !== defaultValue) {
      selectValue(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (fromSearch) {
      if (props.city) setMissingData(false)
    }
  }, [props.city]);

  useEffect(() => {
    if (props.adminValue) selectValue(-1)
  }, [props.adminValue])

  return <S.SelectField error={props.error ? true : false} >
    {props.label && <label>{props.label}{props.required && !props.noStar && '*'}</label>}
    <S.Flex>
      {props.icon && <SvgComponent svgCode={props.icon} />}
      <S.Select icon={props.icon ? 1 : 0}>
        <select value={select} onChange={handleChange} name={props.name} required={props.required} data-default={defaultValue}>
          {/* <option value={-1}>{props.label}</option> */}
          {props.placeholder && <option value={-1} disabled selected>{props.placeholder}</option>}
          {props.data.map((value) => (
            <option key={value.id} value={value.id}>{props.admin ? 'Niv: ' + value.label + ' - Points: ' + value.points + ' - Récompense: ' + value.reward : value.label}</option>
          ))}
        </select>
      </S.Select>
    </S.Flex>
    {missingData && <S.Error>Veuillez renseigner une ville</S.Error>}
    {props.error && <span>{props.error}</span>}
  </S.SelectField>
}

// 
// ─── CHECKBOX ───────────────────────────────────────
//
export const CheckboxField = (props) => {
  const [checked, setChecked] = useState(props.checked || false)

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (props.handleChange) {
      props.handleChange(event.target)
    }
    if (props.handleSearch) {
      props.handleSearch({ [event.target.name]: event.target.checked })
    }
  };

  // RESET VALUE INPUT ON RESET PASSWORD
  if (props.reset && checked) {
    setChecked(false)
  }

  return <S.CheckboxField error={props.error ? true : false}>
    <label htmlFor={props.name}>
      <input type="checkbox" checked={checked} required={props.required} onChange={handleChange} onClick={props.onClick} name={props.name} disabled={props.disabled} />
      {props.label}{props.required && !props.noStar && '*'}
    </label>
    {props.error && <span>{props.error}</span>}
  </S.CheckboxField>
}

// 
// ─── MULTIPLE ───────────────────────────────────────
//
export const Multiple = (opt) => {
  const field = opt.field
  const fieldName = field.name
  const titles = field.titles
  const defaults = getValue(field, ['default'])

  const componentValue = getValue(field, ['components', 'value'])
  const componentLabel = getValue(field, ['components', 'label'])
  const componentLabelType = getValue(field, ['components', 'label_type'])

  // 
  // ─── CHAMPS NECESSAIRES ───────────────────────────────────────
  //
  let multipleFields = {}

  if (defaults) {
    Object.values(JSON.parse(defaults)).forEach((el, index) => {
      const elementId = `${field.name}_${index}`
      multipleFields[elementId] = [
        { type: componentLabelType, component: componentLabel, label: titles.label, value: el.label, name: `${field.name}_label_${index}` },
        { type: "text", component: componentValue, label: titles.value, value: el.value, name: `${field.name}_value_${index}`, multiline: field.multiline, hidden: field.hidden }
      ]
    })
  } else {
    multipleFields = {
      1: [
        { type: componentLabelType, component: componentLabel, label: titles.label, name: `${fieldName}_label_1` },
        { type: "text", component: componentValue, label: titles.value, name: `${fieldName}_value_1`, multiline: field.multiline, hidden: field.hidden }
      ]
    }
  }

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [fields, setFields] = useState(multipleFields)

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const S = {}
  S.MultipleContainer = styled.div`
    display: flex;
    flex-direction: column;
  `

  S.MultipleFields = styled.div`
    display: flex;
    margin-top: 20px;
  `

  S.Button = styled(Button)`
    margin-top: 20px !important;
  `

  return (
    <div>
      <label>{field.label}</label>
      <S.MultipleContainer className="form__multiple">
        {Object.values(fields).map((element, index) => {
          return <S.MultipleFields key={index}>
            {
              element.map((el, i) => {
                return <Formfields key={i} field={{ ...el, ...{ dataSet: { 'data-attribute': 'multiple', 'data-name': fieldName } } }} classNameOuter={i === 0 ? 'input-right' : 'input-left'} />
              })
            }
          </S.MultipleFields>
        })}
      </S.MultipleContainer>
      {!field.removeAdd && <S.Button variant="contained" onClick={(event) => {
        event.preventDefault();
        let index = Object.keys(fields).length + 1;

        // 
        // ─── AJOUT D'UN ELEMENT SUPPLEMENTAIRE A LA LISTE ───────────────────────────────────────
        //
        setFields(fields[index] = {
          ...fields,
          [`${field.name}_${index}`]: [
            { type: componentLabelType, component: componentLabel, label: titles.label, name: `${fieldName}_label_${index}` },
            { type: "text", component: componentValue, label: titles.value, name: `${fieldName}_value_${index}`, multiline: field.multiline, hidden: field.hidden }
          ]
        })
      }}>Ajouter</S.Button>}
    </div>
  )
}

// 
// ─── UPLOAD FILE ───────────────────────────────────────
//
export const UploadFile = (props) => {
  const [file, setFile] = useState(props.default || { name: '' })

  const handleChange = (e) => {
    setFile({ name: e.target.files[0].name })
    if (props.onChange) {
      props.onChange(e)
    }
  }

  return <S.UploadFile error={props.error ? true : false}>
    <label htmlFor={props.name}>
      {props.label}{props.required && !props.noStar && '*'}
      {!file.name && props.error && <span className="error">{props.error}</span>}
      {!file.name && <div>
        <span className="download">Télécharger</span>
        <p>Aucun fichier choisi.</p>
      </div>}
      {file.name && <div>
        <p>{file.name}</p>
        <svg onClick={(e) => {
          e.preventDefault();
          document.querySelector(`input[name='${props.name}']`).value = null;
          setFile({ name: '' });
        }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
      </div>}
    </label>
    <input type="file" name={props.name} id={props.name} required={props.required} onChange={handleChange} accept="image/png, image/jpg, .pdf" />
  </S.UploadFile>
}

// 
// ─── DATE AND TIME PICKER ───────────────────────────────────────
//
const DatePicker = (props) => {
  let defaultDate = formatDate({ time: props.default || Date.now(), display: 'datetimepicker' })
  const [date, setDate] = useState(defaultDate);

  const handleChange = (e) => {
    setDate(e.target.value);
    if (props.handleChange) {
      props.handleChange(e.target.value)
    }
  };

  return <S.DatePicker error={props.error ? true : false}>
    {props.label && <label htmlFor={props.name}>{props.label}{props.required && !props.noStar && '*'}</label>}
    <input
      type="date"
      name={props.name}
      id={props.name}
      required={props.required}
      value={date}
      onChange={handleChange}
    />
    {props.error && <span>{props.error}</span>}
  </S.DatePicker>
}

// 
// ─── BUTTON GROUP ───────────────────────────────────────
//
const GroupedButtons = (opt) => {
  const field = opt.field;
  const [counter, setCounter] = useState(1);

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const S = {}
  S.Label = styled.span`
    margin-right: 10px;
  `

  // 
  // ─── HANDLE INPUT INCREMENTATION OR DECREMENT ───────────────────────────────────────
  //
  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    setCounter(counter - 1);
  };
  return (
    <div>
      <S.Label>{field.label}</S.Label>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button disabled={counter === 1} onClick={handleDecrement}>-</Button>
        <TextField
          size="small"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          name={field.name}
          value={counter} onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value <= 8) {
              setCounter(parseInt(e.target.value))
            }
          }}
          style={{ width: 40 }}
        />
        <Button disabled={counter === 8} onClick={handleIncrement}>+</Button>
      </ButtonGroup>
    </div>
  );
}
//
// ─── SEARCH AUTOCOMPLETE ───────────────────────────────────────
//
const SearchField = (props) => {
  const field = props.field;
  const data = field.data;

  const defaultValue = getValue(field, ['default']) || field.value || ''
  const [value, setValue] = useState(field.multiple ? defaultValue.split(',').filter(v => v) || [] : defaultValue || '');

  useEffect(() => {
    if (field.default !== value) {
      setValue(field.multiple && field.default ? field.default.split(',').filter(v => v) || [] : field.default || '')
    }
  }, [field.default])

  let multipleValue

  if (value && value.length) {
    multipleValue = value.map((val) => {
      if (val.id) {
        return val.id
      } else {
        return val
      }
    })
  }

  return <>
    <Autocomplete
      id={props.name}
      size="small"
      freeSolo
      multiple={field.multiple}
      options={data}
      noOptionsText={'Aucun résultat'}
      getOptionLabel={(option) => option.label || option || ''}
      onChange={(e, inputValue) => {
        setValue(inputValue)
      }}
      defaultValue={value}
      className={props.className}
      renderInput={(params) =>
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={field.label}
          value={field.default}
          placeholder={field.placeholder}
        />
      }
    />
    {value && <input type='hidden' name={field.name} value={field.multiple && multipleValue ? multipleValue.join(',') : value.id} />}
  </>
}

//
// ─── SEARCH AUTOCOMPLETE CTITIES ───────────────────────────────────────
//
const SearchFieldCities = (props) => {
  const defaultValue = getValue(props, ['default']) || props.value || ''
  const [value, setValue] = useState(defaultValue || '');
  const [cities, setCities] = useState([]);
  const [noOptions, setNoOptions] = useState(false);
  const timer = useRef();

  const fetchCities = async (value) => {
    setNoOptions(false);
    const { success, data } = await Api({ endpoint: '/cities', method: 'GET', query: { fields: ['label, zip_code'], label: value } });
    if (!success) setNoOptions(true);
    return data;
  }

  const debouncedFetch = useCallback(
    debounce((query) => {
      fetchCities(query).then(setCities);
    }, 500), []);

  // To  make only one api call when the user stops typing
  useEffect(() => {

    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (value) {
      timer.current = setTimeout(() => {
        debouncedFetch(value);
      }, 500);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    }

  }, [value, debouncedFetch]);

  const handleChange = (event, value) => {
    if (props.handleSearch) {
      let data = { 'zip_code': false }
      if (value) data = { 'zip_code': value.zip_code, 'label': value.label }
      props.handleSearch(data)
    }
  }

  return <>
    <Autocomplete
      id={props.name}
      size="small"
      freeSolo
      options={cities}
      getOptionLabel={(option) => option.label || option || ''}
      renderOption={(props, option) => (
        <div component="li" {...props}>
          {option.label.charAt(0).toUpperCase() + option.label.slice(1)} ({option.zip_code})
        </div>
      )}
      onInputChange={(e, inputValue) => { setValue(inputValue) }}
      onChange={handleChange}
      className={props.className}
      defaultValue={defaultValue}
      renderInput={(params) => {
        return <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={props.label}
          placeholder={props.placeholder}
        />
      }
      }
    />
    {noOptions && <S.Error className="error">Aucun résultat</S.Error>}
    {props.error && <span>{props.error}</span>}
  </>
}

// 
// ─── SEARCH SELECT ───────────────────────────────────────
//
export const SearchSelect = (props) => {
  let defaultValue, defaultProps = getValue(props, ['default']);
  if (defaultProps !== undefined) defaultValue = defaultProps
  else defaultValue = '';

  defaultValue = typeof defaultValue === 'string' ? parseInt(defaultValue) : defaultValue;

  const [select, selectValue] = useState(defaultValue);
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (defaultValue !== undefined && select !== defaultValue) {
      selectValue(defaultValue)
    }
  }, [defaultValue])

  const filter = (jobs) => {
    return jobs.filter(
      (job) => job.label.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return <S.TextField error={props.error ? true : false} icon={props.icon ? 1 : 0}>
    {props.label && <label>{props.label}{props.required && !props.noStar && '*'}</label>}
    <S.Flex>
      {props.icon && <SvgComponent svgCode={props.icon} />}
      <S.SelectSearch icon={props.icon ? 1 : 0}>
        {/* input hidden to send the correct value (number) to the db */}
        <input type="hidden" name={props.name} data-default={defaultValue} value={select} />
        <div className="searchInput">
          <input type="text" onClick={() => setOpen(true)} onChange={(e) => { setQuery(e.target.value); selectValue(null) }} placeholder="Rechercher un métier" required={props.required} value={select ? props.data?.find(job => job.id === select).label : query} />
        </div>
        <S.Dropdown search={open ? 1 : 0}>
          {filter(props.data).map((job) => {
            return (
              <div
                onClick={() => { selectValue(job.id); setOpen(false) }}
                key={job.id}
              >
                {job.label}
              </div>
            );
          })}
        </S.Dropdown>
      </S.SelectSearch>
    </S.Flex>
    {props.error && <span>{props.error}</span>}
  </S.TextField>
}

// 
// ─── SEARCH SELECT MULTIPLE ───────────────────────────────────────
//
export const SearchSelectMultipleJobs = (props) => {
  const dataJobs = props.data || window._DATA.jobs;
  let error = props.error || '';
  const [jobs, setJobs] = useState({ filtered: [], initial: dataJobs });
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [maxSelected, setMaxSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [empty, setEmpty] = useState(false);

  const inputValue = useRef("");

  const jobIds = selectedJobs?.map((job) => job.job_id);

  useEffect(() => {
    const filteredJobs = props?.default?.map(id => { return dataJobs.find(job => job.job_id === id) });
    setSelectedJobs(filteredJobs || []);
  }, [props.default])

  //
  // ─── SEARCH JOBS ───────────────────────────────────────
  //
  const handleSearch = (e) => {
    setOpen(true);
    setEmpty(false);
    const value = e.target.value;
    inputValue.current = value;

    const includedJobName = (job) => job.label.toLowerCase().includes(value.toLowerCase());
    const notAlreadySelected = (job) => !selectedJobs.find(selectedJob => selectedJob.job_id === job.job_id);

    if (!value) return setJobs({ ...jobs, filtered: jobs.initial.filter(job => notAlreadySelected(job)) });

    const filtered = jobs.initial.filter(job => includedJobName(job) && notAlreadySelected(job));
    if (!filtered.length) {
      setEmpty(true)
      return setJobs({ ...jobs, filtered: [] });
    }
    setJobs({ ...jobs, filtered });
  }
  //
  // ─── ADD A JOB TO SELECTED ───────────────────────────────────────
  //
  const handleJobClick = (job_id) => {
    setOpen(false);
    setEmpty(false);
    if (selectedJobs.length === 3) { return setMaxSelected(true) }
    const job = jobs.initial.find(j => j.job_id === job_id);
    if (!job) return;

    // remove selected from filtered
    setJobs({ ...jobs, filtered: jobs.filtered.filter(filteredJob => filteredJob.job_id !== job_id) });

    setSelectedJobs(prev => ([...prev, job]));
  }

  //
  // ─── REMOVE A JOB FROM SELECTED ───────────────────────────────────────
  //
  const removeJob = (job_id) => {
    setSelectedJobs(selectedJobs.filter(job => job.job_id !== job_id));
  }

  return <>
    <S.TextField error={props.error ? true : false} icon={props.icon ? 1 : 0}>
      {props.label && <label>{props.label}{props.required && !props.noStar && ''}</label>}
      <S.Flex>
        {props.icon && <SvgComponent svgCode={props.icon} />}
        <S.SelectSearch icon={props.icon ? 1 : 0}>
          {/* input hidden to send the correct values (array) to the db */}
          <input type="hidden" name={props.name} data-default={props.default} value={jobIds} required={props.required} />
          <div className="searchInput">
            <input onFocus={handleSearch} className="content-secondary" id="search" onChange={handleSearch} value={inputValue.current} />
          </div>
          <S.Dropdown search={open ? 1 : 0} className="dropdown-jobs">
            {jobs.filtered.sort((a, b) => a.label.localeCompare(b.label)).map((job) => (
              <div key={job.job_id} onClick={() => handleJobClick(job.job_id)}>
                <span className="job-label">{job.label}</span>
              </div>
            ))}
            {empty && <div className="empty">Aucun résultat</div>}
          </S.Dropdown>
        </S.SelectSearch>
      </S.Flex>
      {props.error && <span>{props.error}</span>}
    </S.TextField>
    <S.SelectedValues>
      {selectedJobs && selectedJobs.map((job) => (
        <div key={job.job_id} className="selectedItem">
          {job.label}
          <svg onClick={() => removeJob(job.job_id)} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 9L9 3" stroke="#757575" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 9L3 3" stroke="#757575" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      ))}
      {maxSelected && <span className="error">Vous ne pouvez pas sélectionner plus de 3 métiers</span>}
    </S.SelectedValues>
  </>;
};

//
// ─── GENERATION DU RENDER ───────────────────────────────────────
//
const Formfields = (props) => {
  const component = props.field.component || 'text';
  const width = props.field.width || '100%';

  const components = {
    text: Text,
    textarea: Textarea,
    switch: SwitchField,
    radio: RadioField,
    select: SelectField,
    checkbox: CheckboxField,
    multiple: Multiple,
    file: UploadFile,
    datepicker: DatePicker,
    grouped_btn: GroupedButtons,
    search: SearchField,
    search_cities: SearchFieldCities,
    search_select: SearchSelect,
    search_select_multiple_jobs: SearchSelectMultipleJobs
  };

  const getComponent = (name) => {
    if (!components[name]) return null;

    const Render = components[name];
    return <Render {...props.field} />;
  };

  return (
    <S.Container width={width} className={'formfield_container ' + props.classNameOuter}>
      {getComponent(component)}
    </S.Container>
  );
};

export default Formfields;

// 
// ─── INITIALISATION DES STYLES ───────────────────────────────────────
//
const S = {}
S.InputContainer = styled.div`
  flex-direction: column;
  display: ${({ hidden }) => hidden ? 'none' : 'flex'};
  width: ${({ width }) => width};

  @media screen and (max-width:780px) {
    width: 100% !important;
  }

  .input-left {
    margin-left: 5px;
  }
  .input-right {
    margin-right: 5px;
  }
`

S.Container = styled.div`
    width: ${({ width }) => width};
`;

S.TextField = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;

    & label {
      color: ${({ theme }) => theme.textSecondary};
      font-size: 14px;
      margin-bottom: 10px;
    }

    & input, textarea {
      border-radius: 2px;
      height: 44px;
      padding-left: 10px;
      padding-right: ${({ password }) => Boolean(password) ? '40px' : '10px'};
      font-size: 15px;
      border: none;
      border-left: ${({ icon }) => Boolean(icon) ? 'none' : '3px solid #F18903'};
      width: 100%;
      box-sizing: border-box;
      color: ${({ theme }) => theme.textSecondary};
      background: rgba(255, 255, 255, 0.14);
      font-family: ${({ theme }) => theme.primaryFont};
      
      &:focus, :not(&[value=""]) {
        outline: none;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0px 1000px white inset;
      -webkit-text-fill-color: black;
    }
  & span {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }
`

S.Textarea = styled(S.TextField)`
  & label {
    top: 17px;
  }
  textarea {
    min-width: 100%;
    height: 175px;
    resize: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

S.Flex = styled.div`
display: flex;
position: relative;
`

S.PasswordEye = styled.div`
  position: absolute;
  height: 44px;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  display: flex;
  align-items: center;

  svg {
    width: 17px;
    height: 17px;
    fill: ${({ theme }) => theme.secondary};
    opacity: 0.7;

    &:last-child {
      display: none;
    }
  }

  &.active {
    svg:first-child {
      display: none;
    }
    svg:last-child {
      display: block;
    }
  }
`

S.CheckboxField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & label {
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.textSecondary};

    & input {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
      font: inherit;
      color: ${({ theme }) => theme.primary};
      width: ${({ theme }) => theme.width > 800 ? '1.30em' : '1.70em'};
      height: 1.30em;
      border: ${({ theme, error }) => `${error ? theme.error : theme.primary} 0.15em solid`};
      border-radius: 0.15em;
      display: grid;
      transform: translateY(-0.075em);
      place-content: center;

      &::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: ${({ theme }) => `${theme.primary} inset 1em 1em`};
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }
  & span {
    margin-top: 5px;
    font-size: 13px;
    font-weight: bold;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }
`

S.RadioField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;

  // radio group
  & > .container {
    display: flex;
    gap: 15px;
    flex-direction: ${({ direction }) => direction};
    align-items: center;
    justify-content: center;
  }

  & > span:last-child {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }
`

S.ImageContainer = styled.div`
width: 50px;
height: 50px;
margin-bottom: 20px;

.imgSelect {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(40%);
}

&.active {
  width: 100px;
  height: 100px;

  .imgSelect {
    filter: none;
  }
}
`

S.Radio = styled.label`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  color: white;
  user-select: none;
  -webkit-tap-highlight-color: transparent; /* For older versions of iOS */
  user-select: none;

  input {
    display: none;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    transition: .3s;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.primary};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`

S.LabelRadio = styled.div`
color: ${({ theme }) => theme.secondary};
font-weight: bold;
font-size: 24px;
margin: 0 auto
`

S.SelectField = styled.div`
  display: flex;
  flex-direction: column;

  & label {
    font-size: 14px;
    color: ${({ theme }) => theme.textSecondary};
    margin-bottom: 10px;
  }

  & span:last-of-type {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }
`

S.Select = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-size: 15.5px;
  cursor: pointer;
  border-radius: 2px;
  display: grid;
  grid-template-areas: "select";
  grid-area: select;
  align-items: center;
  border: none;

  select {
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.14);
    border-left: ${({ icon }) => Boolean(icon) ? 'none' : '3px solid #F18903'};
    height: 44px;
    appearance: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    padding-left: 10px;
    color: ${({ theme }) => theme.textSecondary};
    background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="grey" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>') no-repeat;
    background-position: 95% 50%;
  }

  /* &::after {
    content: "";
    width: 8px;
    height: 8px;
    border-top: ${({ theme }) => `1px solid ${theme.textSecondary}`};
    border-right: ${({ theme }) => `1px solid ${theme.textSecondary}`};
    transform: rotate(135deg) translate(-1px, 1px);
    justify-self: end;
    grid-area: select;
    justify-self: end;
    margin-right: 10px;
  } */

  select::-ms-expand {
    display: none;
  }
  select, select:after {
    grid-area: select;
  }
`

S.SelectSearch = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-size: 15.5px;
  cursor: pointer;
  border-radius: 2px;
  align-items: center;
  border: none;

  & .searchInput {
    position: relative;
  }
  
  & .searchInput::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-top: ${({ theme }) => `1px solid ${theme.textSecondary}`};
    border-right: ${({ theme }) => `1px solid ${theme.textSecondary}`};
    transform: rotate(135deg) translate(-1px, 1px);
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 10px auto 0;
  }
`

S.SelectedValues = styled.div`
display: flex;
flex-wrap: wrap;
padding: 10px 0;
gap: 10px;

& .selectedItem {
  color: white;
  border: 1px solid ${({ theme }) => theme.primary};
  background: rgba(255,255,255,0.14);
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

& svg {
  width: 15px;
  height: 15px;
  fill: white;
  margin:0 5px;
  cursor: pointer;
}

& .error {
  margin-left: 5px;
  font-weight: bold;
  color : ${({ theme }) => theme.error};
}
`

S.Dropdown = styled.div`
display: ${({ search }) => search ? 'flex' : 'none'};
box-sizing: border-box;
margin-top: 5px;
flex-direction: column;
gap: 10px;
border-radius: 2px;
background: rgba(255, 255, 255, 0.14);
border-right: none;
border-top: none;
border-bottom: none;
padding: 1em;
width: 100%;
font-family: inherit;
font-size: inherit;
cursor: inherit;
line-height: inherit;
outline: none;
padding-left: 10px;
color: ${({ theme }) => theme.textSecondary};
max-height: 150px;
overflow-y: scroll;

&::-webkit-scrollbar {
  width: 3px;
}

&::-webkit-scrollbar-thumb {
  background: #F18903;
  border-radius: 5px;
}
`

S.DatePicker = styled(S.TextField)`
`

S.UploadFile = styled.div`
  display: flex;
  flex-direction: row;

  label {
    font-size: 14px;
    width: 100%;
    color: ${({ theme }) => theme.textSecondary};

    .download {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      border-radius: 5px;
      color: ${({ theme }) => theme.textSecondary};
      background: ${({ theme }) => theme.primary};
      padding: 11px 0;
      margin-top: 5px;
      width: 50%;
      cursor: pointer;
    }

    .error {
      margin-left: 5px;
      font-weight: bold;
    }

    & div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 10px;
      
      & svg {
        width: 20px;
        height: 20px;
        fill: ${({ theme }) => theme.primary};
        cursor: pointer;
      }
    }
  }

  p {
    text-align: center;
    color: ${({ theme }) => theme.textSecondary};
    background: ${({ theme }) => theme.inputBg};
    border-radius: 5px;
    margin-top: 5px;
    padding: 5px 0;
    font-size: 14px;
  }

  input {
    display: none;
  }

  & span {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }
`

S.Error = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.error};
`