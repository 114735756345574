// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import styled from "styled-components/macro";
import { EmptyContainer } from "../../Style/StyledComponents";
import AccordionDesktop from "./AccordionDesktop";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListDesktop = ({ sponsored, offers, offerEdit, edit }) => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return !sponsored[0].none ? <div>
    <S.Header>
      <div>Nom</div>
      <div>Email</div>
      <div>Origine du filleul</div>
    </S.Header>
    {sponsored && sponsored.map((user) => {
      return <AccordionDesktop key={user.user_id} offerEdit={offerEdit} user={user} offers={offers} edit={edit} />
    }
    )}
  </div> : <EmptyContainer>Pas d'utilisateurs trouvés.</EmptyContainer>
};

export default ListDesktop;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
color: ${({ theme }) => theme.textPrimary};
font-size: 18px;
font-weight: 600;
width: 65%;
`

S.Table = styled.table`
width: 100%;
border-collapse: collapse;

& th, td {
padding: 20px 20px 20px 0;
}

& tr {
  border-bottom: 1px solid #B4B4B4;
}
`

S.TableHeader = styled.tr`
width: 100%;
color: ${({ theme }) => theme.textPrimary};
font-size: 18px;
font-weight: 600;
`

S.Sponsored = styled.tr`
padding: 10px;
color: ${({ theme }) => theme.textSecondary};
`

S.Checked = styled.div`
width: ${props => props.active ? '25' : '19'}px;
height: ${props => props.active ? '25' : '19'}px;
border: 2px solid ${props => props.active ? props.status === 1 ? '#ECBA79' : '#F18903' : '#DDDDDD'};
border-radius: 50%;
background: ${props => props.active ? props.status === 1 ? '#ECBA79' : '#F18903' : 'transparent'};
`

S.Action = styled.div`
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
background: ${({ theme }) => theme.primary};
width: 30px;
height: 30px;

& svg {
  width: 15px;
  height: 15px;
  fill: ${({ theme }) => theme.secondary};
}
`
