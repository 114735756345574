// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import styled from 'styled-components/macro';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const NotFound = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <h1>404 Not Found</h1>
  </S.Container>
};

export default NotFound;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`

`