// 
// ─── IMPORT ───────────────────────────────────────
//
// ** UTILS **
import { Container, Title } from "../../components/Style/StyledComponents";
import RewardsList from "../../components/Admin/Rewards/RewardsList";
import UsersLists from "../../components/Admin/UsersLists";
import ApplyList from "../../components/Admin/ApplyList";

import styled from "styled-components/macro";
import { breakpoints } from "../../components/Style/StyledBreakpoints";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Admin = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Container>
    <Title>Gestion administration</Title>
    <S.AdminContainer>
      <S.LeftContainer>
        {/* Rewards list */}
        <RewardsList />
        {/* Users list */}
        <UsersLists />
      </S.LeftContainer>
      <S.RightContainer>
        {/* Postulations list */}
        <ApplyList />
      </S.RightContainer>
    </S.AdminContainer>
  </Container>
};

export default Admin;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.AdminContainer = styled.div`
display: flex;
flex-direction: row;
${breakpoints("flex-direction", "", [
  { 800: "column" },
])};
gap: 40px;
box-sizing: border-box;
align-items: stretch;
`

S.LeftContainer = styled.div`
  width: 50%;
  ${breakpoints("width", "", [
  { 800: "100%" },
])};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
`

S.RightContainer = styled.div`
  width: 100%;
  ${breakpoints("width", "", [
  { 1000: "50%" },
  { 800: "100%" },
])};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`