import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import Cookies from "universal-cookie";
import { getValue, prepareFormData } from "../contexts/Utils";
import Api from '../contexts/Api';
import { useNotification } from "../components/UseNotification";
import Formfields from "../components/Formfields";
import { BackContainer, BetterButton, Button } from "../components/Style/StyledComponents";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ForgotPassword = () => {
  const { notification } = useNotification();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({})

  const handleFields = (opt) => {
    let key = Object.keys(opt)[0]
    let value = Object.values(opt)

    setFields(prev => ({ ...prev, [key]: value[0].length > 0 ? true : false }))
  }

  // 
  // ─── HANDLE SUBMIT ───────────────────────────────────────
  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = prepareFormData({ formId: 'form' });
    if (data.errors) return setErrors(data.errors);
    else setErrors({});

    const params = {
      data: { fields: data.fields },
      endpoint: '/users/password/reset'
    }

    const resetPassword = await Api(params)
    notification({ variant: resetPassword.success ? 'success' : 'error', message: resetPassword.message })
    if (resetPassword.success) navigate('/connexion')
  }

  return user ? <Navigate to="/" /> : <>
    <BackContainer onClick={() => navigate('/connexion')}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 256C448 264.8 440.6 272 431.4 272H54.11l140.7 149.3c6.157 6.531 5.655 16.66-1.118 22.59C190.5 446.6 186.5 448 182.5 448c-4.505 0-9.009-1.75-12.28-5.25l-165.9-176c-5.752-6.094-5.752-15.41 0-21.5l165.9-176c6.19-6.562 16.69-7 23.45-1.094c6.773 5.938 7.275 16.06 1.118 22.59L54.11 240h377.3C440.6 240 448 247.2 448 256z" /></svg>
      <span>
        Retour
      </span>
    </BackContainer>
    <S.TitleContainer>Réinitialiser le mot de passe</S.TitleContainer>
    <S.Form id='form'>
      <Formfields field={{ type: "email", component: "text", name: "email", label: "Email", required: true, error: getValue(errors, ['email', 'message']), handleSearch: (e) => handleFields(e) }} />
      <S.ButtonContainer>
        <BetterButton filled={fields.email ? true : false} as="input" type="submit" value="Réinitialiser" onClick={handleSubmit}></BetterButton>
      </S.ButtonContainer>
    </S.Form>
  </>
}

export default ForgotPassword;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.TitleContainer = styled.h1`
  margin: 0 auto;
  font-size: 24px;
  font-weight: bold;
  color: white
`

S.Form = styled.form`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;

  & > h1 {
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    color: white
  }
`

S.ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;

  & > input {
    min-width: 100%;
    border-radius: 5px;
    margin-top: 10px;
  }

  & > svg {
    position: absolute;
    color: ${({ theme }) => theme.secondary};
    top: 0;
    bottom: 0;
    margin: 0 auto;
  }
`

S.Button = styled(Button)`
border: 2px solid ${props => props.filled ? 'rgba(255, 255, 255, 0.4)' : 'white'} ;
background-size: 200%;
transition: .5s ease-out;
background: ${props => props.filled ? props.theme.primary : 'transparent'};

&:hover {
  background: ${props => props.filled ? props.theme.hoverButton : props.theme.secondary};
  color: ${props => props.filled ? 'white' : props.theme.primary};
}
`