// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
// 
// ** UTILS **
import Api from "../../contexts/Api";
//
// ** COMPONENTS **
import { AdminComponent, AdminUsersContainer, Button, EmptyContainer } from "../Style/StyledComponents";
import EditUser from "../Modals/Admin/EditUser";
import Loading from "../Loading";
import SearchBar from "../SearchBar/SearchBar";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const UsersLists = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [users, setUsers] = useState({ all: [], filtered: [] })
  const [openModal, setOpenModal] = useState({ open: false, user: null })
  const [loading, setLoading] = useState(true)
  const [empty, setEmpty] = useState(false)

  // 
  // ─── GET REWARDS ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsers = async () => {
      const data = await Api({
        endpoint: '/users',
        method: 'GET'
      });

      if (data.success) { setUsers({ all: data.data, filtered: [] }) }
      else { setEmpty(true) }
      setLoading(false)
    }
    if (!users.all.length && !empty) getUsers()
  }, [users])

  // 
  // ─── UPDATE USERS LIST ───────────────────────────────────────
  //
  const updateUsersList = (opt) => {
    let indexUser = users.all.findIndex(user => user.user_id === opt.user_id)
    users.all[indexUser] = { ...users.all[indexUser], ...opt }
    if (users.filtered.length) {
      let indexUser = users.filtered.findIndex(user => user.user_id === opt.user_id)
      users.filtered[indexUser] = { ...users.filtered[indexUser], ...opt }
    }
    setUsers({ ...users })
  }

  //
  // ─── HANDLE SEARCH ───────────────────────────────────────
  //
  const handleSearch = (value) => {
    let search = users.all.filter(user => {
      //search by nom prenom
      return (
        user.firstname.toLowerCase().includes(value) ||
        user.firstname.toUpperCase().includes(value) ||
        user.name.toLowerCase().includes(value) ||
        user.name.toUpperCase().includes(value)
      )
    })
    if (!search.length) search = [{ none: true }]
    setUsers({ ...users, filtered: search })
  }

  let usersList = users.all
  if (users.filtered.length) {
    usersList = users.filtered
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <AdminComponent>
    <S.Title>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" /></svg>
      Utilisateurs
    </S.Title>
    <SearchBar disabled={true} users={true} change={handleSearch} />
    <Loading loading={loading}>
      <S.UsersContainer>
        {usersList.map((user) => {
          return user.none ? <EmptyContainer>Pas d'utilisateurs trouvés.</EmptyContainer> : <AdminUsersContainer key={user.user_id}>
            <div className="infosUser">
              <span>Nom</span>
              {user.name + ' ' + user.firstname}
            </div>
            <Button onClick={() => setOpenModal({ open: true, user: user })}>{window.innerWidth > 800 && 'Modifier'}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" /></svg></Button>
          </AdminUsersContainer>
        })
        }
      </S.UsersContainer>
    </Loading>
    {empty && <EmptyContainer>
      <h1>
        Oops ...
      </h1>
      Pas d'utilisateurs pour le moment.
    </EmptyContainer>}
    {/* MODAL CHANGE USER INFOS */}
    {openModal.open && <EditUser onUpdate={updateUsersList} user={openModal.user} onClose={() => setOpenModal({ open: false, user: null })} />}
  </AdminComponent>
};

export default UsersLists;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Title = styled.div`
display: flex;
align-items: center;
gap: 10px;
color: ${({ theme }) => theme.textSecondary};;
font-size: 20px;
font-weight: 700;

& svg {
  fill: ${({ theme }) => theme.primary};
  width: 20px;
  height: 20px;
}

& button {
  margin-left: ${({ theme }) => theme.width > 800 ? '40px' : 'auto'};
}
`

S.UsersContainer = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
max-height: 400px;
overflow-y: auto;
padding-right: 10px;

&::-webkit-scrollbar {
  width: 3px;
}

&::-webkit-scrollbar-thumb {
  background: #F18903;
  border-radius: 5px;
}`

S.RewardsContainer = styled.div`
display: flex;
flex-wrap: wrap;
${({ theme }) => theme.width < 800 && 'justify-content: space-between'};
gap: 15px;
`

S.Reward = styled.div`
display: flex;
flex: 1 1 100%;
max-width: ${({ theme }) => theme.width > 800 ? '15%' : '30%'};
position: relative;
padding-left: 50px;
padding-right: 10px;
height: 44px;
align-items: center;
background: ${({ theme }) => theme.secondary};
border-radius: 20px;
justify-content: center;
overflow: hidden;
gap: 10px;
`

S.Number = styled.div`
position: absolute;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
left: 0;
top: 0;
background: ${({ theme }) => theme.primary};
border-radius: 20px 0px 20px 0px;
width: 44px;
height: 44px;
color: ${({ theme }) => theme.secondary};

& span {
  font-size: 20px;
  font-weight: 600;
}
`

S.Actions = styled.div`
display: flex;
gap: 5px;

& svg {
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.primary};
}
`