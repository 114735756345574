// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Cookies from 'universal-cookie';
// 
// ** UTILS **
import Api from "../contexts/Api";
//
// ** COMPONENTS **
import { Container, EmptyContainer, Title } from "../components/Style/StyledComponents";
import TokenBar from "../components/Rewards/TokenBar";
import ListRewards from "../components/Rewards/ListRewards";
import LevelUp from "../components/Modals/LevelUp";
import Loading from "../components/Loading";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Rewards = ({ noRewards }) => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [rewards, setRewards] = useState([])
  const [users_rewards, setUsersRewards] = useState({ rewards: [], levelUp: [], sponsor: [] })
  const [loading, setLoading] = useState(true)
  const [empty, setEmpty] = useState(false)
  const [modal, setModal] = useState(false)

  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  let showRewardsList = noRewards || false;

  // 
  // ─── GET REWARDS ───────────────────────────────────────
  //
  useEffect(() => {
    const getRewards = async () => {
      const data = await Api({
        endpoint: '/rewards',
        method: 'GET'
      });

      if (data.success) setRewards(data.data)
      else setEmpty(true)
    }
    if (!rewards.length) getRewards(); setLoading(false)
  }, [rewards])

  // 
  // ─── GET USERS LEVEL UP REWARDS ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsersRewards = async () => {
      const data = await Api({
        endpoint: '/users_rewards',
        query: { single_user: userData.user_id },
        method: 'GET'
      });

      if (data.success) {
        setUsersRewards({ rewards: data.data, levelUp: data.data.filter(e => e.show_pop_up === 1 && e.type === 1), sponsor: data.data.filter(e => e.show_pop_up === 1 && e.type === 2) })
      }
    }
    if (!users_rewards.rewards.length) getUsersRewards()
  }, [users_rewards])

  // 
  // ─── SHOW MODAL(S) ───────────────────────────────────────
  //
  useEffect(() => {
    if ((users_rewards.levelUp.length || users_rewards.sponsor.length) && !modal) setModal(true)
  }, [users_rewards, modal])

  // 
  // ─── HANDLE SHOW POP UP ───────────────────────────────────────
  //
  const handleShowPopUp = async (opt) => {
    let data = { fields: { show_pop_up: 0 } }
    if (opt.array === 'levelUp') {
      data.reward_id = opt.reward_id
    } else {
      data.sponsored_id = opt.sponsored_id
      data.offer_id = opt.offer_id
    }
    const update = await Api({
      endpoint: '/users_rewards',
      data,
      method: 'PUT'
    });

    if (update.success) setModal(false); updateRewardsList(opt)
  }

  let nextLevel;
  // Give to the token bar component the next level the user will reach or max level reached
  if (rewards.length) {
    const sortedRewards = rewards.sort((a, b) => a.points - b.points)
    const closestIndex = sortedRewards.findIndex(level => level.points > userData.points);

    if (closestIndex === -1) {
      // User has reached the last level
      const closestLevel = sortedRewards[sortedRewards.length - 1];
      closestLevel['end'] = true
      nextLevel = closestLevel
    } else if (closestIndex === 0) {
      // User has not enough points for the next level
      const closestLevel = sortedRewards[0];
      nextLevel = closestLevel
    } else {
      // User is closest to the level above
      const higherLevel = sortedRewards[closestIndex];
      nextLevel = higherLevel
    }
  }

  // 
  // ─── UPDATE REWARDS LIST FOR MODAL LEVELUP (DELETE) ───────────────────────────────────────
  //
  const updateRewardsList = (opt) => {
    let newRewardsArray = users_rewards[opt.array].filter(reward => opt.array === 'levelUp' ? reward.reward_id !== opt.reward_id : !(reward.offer_id === opt.offer_id && reward.sponsored_id === opt.sponsored_id))
    setUsersRewards({ ...users_rewards, [opt.array]: newRewardsArray })
  }

  // Pass the data to the modal
  // If level up :
  let next_level
  if (users_rewards.levelUp.length) {
    next_level = rewards.filter(obj1 => {
      return users_rewards.levelUp.some(obj2 => {
        return obj1.reward_id === obj2.reward_id;
      });
    });
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Container className="rewards">
    {!showRewardsList && <Title>Mes tokens</Title>}
    {/* PROGRESS BAR */}
    {(rewards && nextLevel && Object.keys(nextLevel).length) && <TokenBar hide={showRewardsList} token={userData.points} reward={nextLevel} />}
    {/* RECOMPENSES */}
    {!showRewardsList && <Loading loading={loading}>
      {rewards && <ListRewards rewards={rewards} loading={loading} user={users_rewards.rewards} />}
      {empty && <EmptyContainer>
        <h1>
          Oops ...
        </h1>
        Pas de récompenses pour le moment.
      </EmptyContainer>}
    </Loading>}
    {modal && <LevelUp next_level={next_level} sponsor={users_rewards.sponsor} onClose={(opt) => handleShowPopUp(opt)} />}
  </Container>
};

export default Rewards;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Form = styled.form`
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & h2 {
    width: 100%;
  color: ${({ theme }) => theme.textPrimary};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 30px;
  ${({ theme }) => theme.width > 800 && 'width: 100%'};
 }

 & input {
    margin: 0 auto;
    ${({ theme }) => theme.width > 800 && 'height: 40px'};
 }

  & .formfield_container {
    margin-bottom: 20px;

    & div {
      padding-right: 0;

      &:after {
        border-top: 1px solid black;
        border-right: 1px solid black;
        margin-right: 10px;
      }

      & select {
      background: ${({ theme }) => theme.secondary};
      border-radius: 5px;
      color: black;
      }
    }
  }
`