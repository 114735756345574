import styled from 'styled-components/macro';

const SvgComponent = ({ svgCode }) => {
  return (
    <S.Svg>
      <div dangerouslySetInnerHTML={{ __html: svgCode }} />
    </S.Svg>
  );
};

export default SvgComponent;

// 
// ─── STYLE DEFINIITION ───────────────────────────────────────
//
const S = {}
S.Svg = styled.div`
background: ${({ theme }) => theme.primary};
display: flex;
align-items: center;
justify-content: center;
width: 44px;
height: 44px;
border-radius: 2px 0px 0px 2px;

& svg {
  //width: 12px;
  height: 12px;
  fill: ${({ theme }) => theme.secondary};
}
`