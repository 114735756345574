// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import styled from "styled-components/macro";
import { Button, Close, ImgLeft, ImgRight } from "../Style/StyledComponents";
//
// ** PNG **
import euro from '../../assets/static/euro.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Sponsor = ({ close }) => {

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <Close onClick={close}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z" /></svg>
    </Close>
    <h2>Parrainage</h2>
    <p>Parrainez des embauches pour gagner des récompenses !</p>
    <p>Jusqu'à <span>1 500<span>&#8364;</span></span></p>
    <Button>Comment ça marche ?</Button>
    <ImgLeft src={euro} alt='euro left' />
    <ImgRight src={euro} alt='euro right' />

  </S.Container>
};

export default Sponsor;

const S = {}
S.Container = styled.div`
width: 100%;
border-radius: 20px;
box-sizing: border-box;
padding: 20px;
background: ${({ theme }) => theme.secondary};
display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
position: relative;
overflow: hidden;
${({ theme }) => theme.width > 800 && 'max-width: 600px'};
${({ theme }) => theme.width > 800 && 'margin: 0px auto'};

& h2 {
  font-size: 24px;
  margin: 0;
  font-weight: 800;
  color: #000000;
  text-align: center;
}

& p {
  text-align: center;

  & span:first-child {
    font-size: 30px;
    font-weight: 700;

    & span:first-child {
      color: ${({ theme }) => theme.textPrimary};
    }
  }
}
`