import Cookies from 'universal-cookie';
import Api from "./Api";
import { useCallback, useEffect, useRef } from 'react';

// 
// ─── COPY AN OBJECT AND RETURN THE VALUE ───────────────────────────────────────
//
export const copyObject = (originalObject) => {
  return Object.assign({}, originalObject)
}

// 
// ─── STATE DECLARATION ───────────────────────────────────────
//
export const getValue = (object, values) => {
  if (!object) {
    return undefined
  }

  let objectValue = object

  values.map((value) => {
    if (objectValue && objectValue.hasOwnProperty(value)) {
      objectValue = objectValue[value]
    } else {
      objectValue = undefined
    }
  })

  return objectValue
}

// 
// ─── PREPARE FORM DATA BEFORE SUBMIT  ───────────────────────────────────────
//
export const prepareFormData = (opt) => {
  let data = {}
  let errors = {}
  let multipleDesc = {}

  Array.from(document.forms[opt.formId]).filter(e => e.getAttribute("name")).forEach((element) => {
    let fieldValue

    if (element.type === 'email') {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegex.test(element.value)) errors[element.name] = { error: true, message: 'Email Invalide' };
    }
    if (element.type === 'radio') {
      if (element.checked) {
        fieldValue = { [element.name]: element.value }
      }
    } else if (element.type === 'checkbox') {
      if (element.required && !element.checked) {
        errors[element.name] = { error: true, message: 'Champ obligatoire' };
      } else if (element.checked && (opt.formId === 'searchBar')) {
        fieldValue = { [element.name]: element.name.split('_')[2] }
      } else if (opt.formId !== 'searchBar') {
        fieldValue = { [element.name]: element.checked ? true : false }
      }
    } else if (element.type === 'file') {
      fieldValue = { [element.name]: element.files[0] }
      if (!element.files[0] && element.required) {
        errors[element.name] = { error: true, message: 'Fichier manquant' };
      } else {
        fieldValue = { [element.name]: element.files[0] }
      }
    } else if (element.dataset.attribute !== 'multiple') {
      const checkField = validateFields({ type: element.type, value: element.value, class: element.className, required: element.required })

      if (checkField.error) {
        errors[element.name] = checkField;
      } else if (element.dataset.default !== element.value) {
        if (element.type === 'hidden' && element.name === "job_ids") {
          fieldValue = { [element.name]: element.value.split(',').map(Number) }
        }
        else fieldValue = { [element.name]: element.value }
      } else if (opt.formId === 'searchBar' && element.value.length) {
        fieldValue = { [element.name]: element.value }
      } else if (opt.formId === 'applyForm') {
        fieldValue = { [element.name]: element.value }
      }
      // Traitement spécifique pour les formulaires multiples
    } else if (element.dataset.attribute === 'multiple' && element.value) {
      const elementId = parseInt(element.name.slice(-1))
      let name = element.name.includes('value') ? 'value' : 'label'
      multipleDesc[elementId] = {

        ...multipleDesc[elementId],
        ...{
          [name]: element.value
        }
      }
    }

    data.fields = {
      ...data.fields, ...fieldValue
    }

    return data
  })

  if (opt.multiple) {
    data.fields[opt.multiple] = JSON.stringify(multipleDesc)
  }

  if (Object.keys(errors).length) {
    data.errors = errors
  }

  return data
}

// 
// ─── FORM FIELD VALIDATION ───────────────────────────────────────
//
const validateFields = (opt) => {
  let result = {};

  if (!opt.value && opt.required) {
    result = { error: true, message: 'Champ obligatoire' }
  } else if (opt.type === 'email') {
    // let re = /^([a-zA-Z0-9-_.])+@\S+\.\S+$/;
    // if (!re.test(opt.value)) {
    //   result = { error: true, message: 'Email invalide' }
    // }
  } else if (opt.class === 'to_check') {
    const inputs = document.querySelectorAll(".to_check");
    if (inputs.length > 1 && (inputs[0].value !== inputs[1].value)) result = { error: true, message: 'Les mots de passe ne correspondent pas' }
  }

  return result;
}


//
// ─── FORMAT DATE ───────────────────────────────────────
//
export const formatDate = (opt) => {

  if (!opt.time) return ''

  let d = new Date(opt.time);

  if (opt.add_time) {
    d = new Date(d.getTime() + (opt.add_time * 1000));
    return d;
  }

  let data = {
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    day: d.getDate(),
    hours: d.getHours(),
    minutes: d.getMinutes(),
    week_day: d.getDay(),
  }

  const monthLabels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  const dayLabels = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

  if (opt.display === 'date_full') {
    data.month = monthLabels[data.month - 1];
    data.week_day = dayLabels[data.week_day];
  }

  Object.entries(data).map(([key, value]) => {
    if ((opt.display === 'date_full' && !['day', 'month'].includes(key)) || opt.display !== 'date_full') {
      return data[key] = (value).toString().padStart(2, 0)
    }
  })

  let year = data.year;
  let month = data.month;
  let day = data.day;
  let hours = data.hours;
  let minutes = data.minutes;
  let week_day = data.week_day;

  let result
  switch (opt.display) {
    case 'hour':
      result = `${hours}h${minutes}`;
      break;
    case 'timer':
      result = `${hours}:${minutes}`;
      break;
    case 'date_full':
      result = capitalizeFirstLetter(`${week_day} ${day} ${month} ${year}`);
      break;
    case 'date_month':
      result = `${monthLabels[data.month - 1]} ${year}`;
      break;
    case 'date':
      result = `${day}/${month}/${year}`;
      break;
    case 'datetimepicker':
      result = `${year}-${month}-${day}`;
      break;
    case 'month':
      result = `${monthLabels[data.month - 1]}`;
      break;
    case 'month_number':
      result = `${data.month}`;
      break;
    case 'day':
      result = `${dayLabels[parseInt(data.week_day)]}`;
      break;
    case 'day_number':
      result = `${data.day}`;
      break;
    case 'week_number':
      result = `${data.week_day}`;
      break;
    default:
      result = `${day}/${month}/${year} - ${hours}h${minutes}`;
      break;
  }

  return result
}

export const formatTime = (duration) => {
  let totalSeconds = duration;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);

  return `${(hours).toString().padStart(2, 0)}:${(minutes).toString().padStart(2, 0)}`
}

//
// ─── CAPITALIZE FIRST LETTER ───────────────────────────────────────
//
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

//
// ─── GET USERS INITIALS ───────────────────────────────────────
//
export const userInitials = (opt) => {
  return `${getValue(opt, ['firstname', 0])}${getValue(opt, ['name', 0])}`
}

//
// ─── FORMAT DATA ───────────────────────────────────────
//
export const formatData = (opt) => {
  let result
  switch (opt.format) {
    case 'hours':
      result = formatTime(opt.value) + ' h'
      break;
    case 'price':
      var int2 = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR", currencyDisplay: "symbol" });
      result = int2.format(opt.value);

      break;
    default:
      result = opt.value;
      break;
  }
  return result;
}

// 
// ─── COPY TEXT TO CLIPBOARD ───────────────────────────────────────
//
export const copyToClipboard = (opt) => {
  let element = opt.element;

  let copyText = document.querySelector(`.${element}`);
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

  navigator.clipboard.writeText(copyText.value);
}

// 
// ─── AUTOSIZE TEXTEAREA ───────────────────────────────────────
//
export const autoSize = (e) => {
  var el = e.target || e;
  setTimeout(function () {
    el.style.cssText = 'height:auto; padding:0';
    el.style.cssText = 'height:' + el.scrollHeight + 'px';
  }, 0);
}

// 
// ─── FILE TO BASE 64 ───────────────────────────────────────
//
export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

// 
// ─── LOGOUT FUNCTION ───────────────────────────────────────
//
export const Logout = async (opt = {}) => {
  const cookies = new Cookies();

  const params = { endpoint: '/auth/logout' }
  const logoutUser = await Api(params)

  if (logoutUser && logoutUser.success) {
    cookies.remove(process.env.REACT_APP_USER_COOKIE)
    window.location.href = `/connexion${opt.query || ''}`
  }
}

// 
// ─── DEBOUNCE FUNCTION ───────────────────────────────────────
//
export const debounce = (func, delay) => {
  let timeoutId;
  return async function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(async () => {
      await func.apply(this, args);
    }, delay);
  };
}

// 
// ─── CLICK OUTSIDE FUNCTION ───────────────────────────────────────
//
export const useOutsideClick = (callback) => {
  const ref = useRef();

  const handleClick = useCallback((event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }, [callback]);

  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [handleClick]);

  return ref;
};