import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { breakpoints } from './StyledBreakpoints';


//
// —————— AUTH ————————————————————————————————————————————————————————————————
//
export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 30px;
  z-index: 1;
  box-sizing: border-box;
  padding: 30px 20px;
  height: ${({ theme }) => theme.height}px;
`

export const BackContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.primary};
  font-size: 12px;
  padding-bottom: 5px;
  margin: 0 auto;

  & span {
    display: inline-block;

    &:after {
    display: block;
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.primary};
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: 0 50%;
  }
  }

  & > svg {
    fill: ${({ theme }) => theme.secondary};
    height: 20px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  padding:${({ theme }) => theme.width > 800 ? '0 80px 60px 80px' : '0 20px'};
  ${({ theme }) => theme.width > 800 && 'max-width: 1280px'};
  ${({ theme }) => theme.width > 800 && 'margin: 0 auto'};
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`

export const Logo = styled(Link)`
  width: 100%;
  height: 70px;
  margin: 0 auto;
  max-width: 70px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover
  }
`

export const Button = styled.button`
  border: none;
  cursor: pointer;
  background: ${({ theme }) => theme.primary};
  padding: 10px 30px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(255, 255, 255, 0.4);
  width: 100%;
  font-family: ${({ theme }) => theme.primaryFont};

  &:hover {
    background: ${({ theme }) => theme.hoverButton};
  }
`

export const ButtonLink = styled.a`
  border: none;
  cursor: pointer;
  background: ${({ theme }) => theme.primary};
  padding: 10px 30px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(255, 255, 255, 0.4);
  width: 100%;
  font-family: ${({ theme }) => theme.primaryFont};

  &:hover {
    background: ${({ theme }) => theme.hoverButton};
  }
`

export const BetterButton = styled(Button)`
border: 2px solid ${props => props.filled ? 'rgba(255, 255, 255, 0.4)' : props.theme.primary} ;
color: ${props => props.filled ? 'white' : props.theme.primary};
background-size: 200%;
transition: .5s ease-out;
background: ${props => props.filled ? props.theme.primary : 'transparent'};

&:hover {
  color: ${({ theme }) => theme.secondary};
}
`

export const BackButton = styled.button`
  cursor: pointer;
  background: none;
  padding: 10px 30px;
  font-size: 17px;
  font-weight: 300;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  width: 65%;
  font-family: ${({ theme }) => theme.primaryFont};

  &:hover {
    background: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.primary};
  }
`

export const LinkAnimated = styled(Link)`
  text-decoration: none;
  display: inline-block;
  color: ${({ theme }) => theme.primary};
  font-size: 12px;
  padding-bottom: 5px;
  margin: 0 auto;

  &:after {
    display: block;
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.primary};
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: 0 50%;
  }
`

//
// –––– OFFER ––––––––––––––––––––––––
//
export const Offer = styled.div`
display: flex;
flex: 1 1 40%;
position: relative;
flex-direction: column;
padding: 20px;
padding-bottom: 30px;
background: ${({ theme }) => theme.secondary};
border-radius: 20px;
/* ${breakpoints("max-width", "", [
  { 800: "40%" },
], "min-width")}; */

& .seeMore {
  position: absolute;
  bottom: -12px;
  right: -5px;
  background: ${({ theme }) => theme.secondary};
  border: 2px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  font-weight: 800;
  font-size: 13px;
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 29px;
}
`

//
// –––– NO RESULTS ––––––––––––––––––––––––
//
export const EmptyContainer = styled.div`
max-width: 600px;
position: relative;
box-sizing: border-box;
width: 100%;
margin: 0 auto;
padding: 20px;
background: ${({ theme }) => theme.secondary};
border-radius: 20px;
color: ${({ theme }) => theme.primary};
text-align: center;

& h1 {
  margin: 0 0 10px 0;
  text-align: center;
}

& svg {
  fill: ${({ theme }) => theme.primary};
  width: 20px;
  height: 20px;
  margin-left: 30px
}

& p {
  font-size: 13px;
  font-weight: 500
}
`

//
// –––– SPINNER ––––––––––––––––––––––––
//
export const Spinner = styled.div`
width: 32px;
height: 32px;
clear: both;
margin: 20px auto;
border: 4px solid transparent;
border-top: 4px solid ${({ theme }) => theme.primary};
border-radius: 50%;
-webkit-animation: spCircRot .6s infinite linear;
animation: spCircRot .6s infinite linear;

@-webkit-keyframes spCircRot {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(359deg); }
}
@keyframes spCircRot {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}
`

//
// –––– FORM ––––––––––––––––––––––––
//
export const Form = styled.form`
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & h2 {
    width: 100%;
  color: ${({ theme }) => theme.textPrimary};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 30px;
  ${({ theme }) => theme.width > 800 && 'width: 100%'};
 }

 & input {
    margin: 0 auto;
    ${({ theme }) => theme.width > 800 && 'height: 40px'};
 }

  & .formfield_container {
    margin-bottom: 20px;

    & div {
      padding-right: 0;

      &:after {
        border-top: 1px solid black;
        border-right: 1px solid black;
        margin-right: 10px;
      }

      & select {
      background: ${({ theme }) => theme.secondary};
      border-radius: 5px;
      color: black;
      }
    }
  }
`

//
// –––– MODAL ––––––––––––––––––––––––
//
export const ModalWrapper = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
z-index: 1000;
padding: 0 20px;
box-sizing: border-box;
`

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const ModalContent = styled.div`
  position: relative;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.secondary};
  padding: 20px;
  max-width: 600px;
  width: 100%;
  //max-height: 70vh;
  overflow: hidden;
`;

//
// –––– IMG IN POP UP / MODAL ––––––––––––––––––––––––
//
export const ImgLeft = styled.img`
position: absolute;
bottom: -10px;
left: -10px;
`

export const ImgRight = styled.img`
position: absolute;
bottom: -10px;
right: -10px;
`

//
// –––– CLOSE BUTTON IN POP UP / MODAL ––––––––––––––––––––––––
//
export const Close = styled.div`
position: absolute;
display: flex;
align-items: center;
justify-content: center;
right: 0;
top: 0;
background: ${({ theme }) => theme.primary};
border-radius: 0px 20px 0px 20px;
width: 35px;
height: 35px;
cursor: pointer;

& svg {
  fill: ${({ theme }) => theme.secondary};
  width: 16px;
  height: 18px;
}
`

//
// –––– REWARDS ––––––––––––––––––––––––
//
export const RewardsContainer = styled.div`
display: flex;
flex-wrap: wrap;
${({ theme }) => theme.width < 800 && 'justify-content: space-between'};
gap: 15px;
`

export const Reward = styled.div`
display: flex;
flex-direction: column;
flex: 1 1 100%;
max-width: ${({ theme }) => theme.width > 800 ? '15%' : '35%'};
position: relative;
align-items: center;
background: ${({ theme }) => theme.secondary};
border-radius: 20px;
justify-content: center;
overflow: hidden;
gap: 10px;
padding: 20px;
`

export const Number = styled.div`
position: absolute;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
left: 0;
top: 0;
background: ${({ theme }) => theme.primary};
border-radius: 20px 0px 20px 0px;
width: 44px;
height: 44px;
color: ${({ theme }) => theme.secondary};
font-size: 10px;

& span {
  font-size: 20px;
  font-weight: 600;
}
`

//
// –––– STEPPER ––––––––––––––––––––––––
//
export const StepperContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 50px;
padding-right: 5px;
position: relative;
width: 100px;
${breakpoints("width", "px", [
  { 800: 135 },
], "min-width")};
`

export const Bar = styled.div`
position: absolute;
top: 50%;
left: 50%;
background: #000000;
${breakpoints("background", "", [
  { 800: "rgba(255, 255, 255, 0.8)" },
], "min-width")};
width: 80%;
${breakpoints("width", "", [
  { 800: "60%" },
], "min-width")};
height: 3px;
border-radius: 10px;
transform: translate(-50%, -50%);
overflow: hidden;
`

export const Steps = styled.div`
display: flex;
position: relative;
align-items: center;
justify-content: center;
border-radius: 50%;
width: ${props => props.active ? '27' : '21'}px;
height: ${props => props.active ? '27' : '21'}px;
border-radius: 50%;
background: ${props => props.active ? '#F18903' : '#5B5B5B'};

&:first-child {
  margin-left: 10px;
  transform: translate(0, 0px);
}

&:first-child:after {
  width: 75px;
  height: 3px;
  display: block;
  background-color: #000000;
  transform: translate(-36px, 0px);
  content: '';
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

& svg {
  fill: ${({ theme }) => theme.secondary};
  width: ${props => props.active ? '15' : '8'}px;
  height:${props => props.active ? '10' : '5'}px;
}
`

//
// –––– ADMIN ––––––––––––––––––––––––
//
export const AdminComponent = styled.div`
border-radius: 20px;
padding: 30px;
gap: 20px;
display: flex;
flex-direction: column;
width: 100%;
position: relative;
overflow: hidden;

&:before {
  content: '';
  position: absolute;
  inset:0;
  z-index:-1;
  background: radial-gradient(72.21% 123.74% at 72.21% 29.94%, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.0525) 100%);
  filter: blur(67.5px);
  border-radius: 20px;
}

& .searchBar {
  max-width: 100%;
}
`

export const AdminRewardsContainer = styled.div`
display: flex;
flex-direction: column;
gap: 30px;
padding: 30px;
background: ${({ theme }) => theme.secondary};
position: relative;
overflow: hidden;
border-radius: 15px;
& h2 {
  font-size: 20px;
  ${breakpoints("font-size", "px", [
  { 800: 17 },
])};
  margin: 0;
  ${breakpoints("margin", "", [
  { 800: "0 auto" },
])};
}
`

export const AdminUsersContainer = styled.div`
border: 2px solid #F18903;
border-radius: 15px;
background: ${({ theme }) => theme.secondary};
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 12px 13px 13px 20px;

& .infosUser {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 15px;

  & span {
    font-weight: 700;
    font-size: 10px;
    color: ${({ theme }) => theme.textPrimary};
  }
}

& button {
  width: fit-content;
  padding: 10px 15px;
  font-weight: 600;
  gap: 10px;

  & svg {
    height: 16px;
    fill: ${({ theme }) => theme.secondary};
  }
}
`