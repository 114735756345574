import { useState, useEffect, useMemo } from "react";
import { Outlet, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Api from "../../contexts/Api";
import { Logout } from "../../contexts/Utils";

const AuthChecker = () => {
  let location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const cookies = useMemo(() => new Cookies(), []);

  const searchParams = new URLSearchParams(location.search);
  const sponsorCode = searchParams.get('sponsor_code');
  // 
  // ─── CHECK IF USER IS CORRECTLY AUTHENTICATED ───────────────────────────────────────
  //
  useEffect(() => {
    const checkUserAuthentication = async () => {
      const params = { endpoint: '/auth/authenticate' }
      const isAuthenticated = await Api(params);

      if (!isAuthenticated.success) {
        let opt = sponsorCode ? { query: `?url=${location.pathname}&sponsor_code=${sponsorCode}` } : {};
        Logout(opt);
      } else {
        setIsAuthenticated(isAuthenticated.success);
        // 
        // ─── RESET COOKIE WITH CURRENT USER INFOS ───────────────────────────────────────
        //
        cookies.remove(process.env.REACT_APP_USER_COOKIE);
        cookies.set(process.env.REACT_APP_USER_COOKIE, isAuthenticated.data, {
          expires: new Date(Date.now() + 604800000), // time until expiration
          secure: false, // true if using https
        })
      }
    }
    checkUserAuthentication();

  }, [location]);

  // 
  // ─── DISPLAY APP PAGES IF USER IS AUTHENTICATED ───────────────────────────────────────
  //
  if (isAuthenticated) {
    return <Outlet />
  }
}

export default AuthChecker;