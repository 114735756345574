import styled, { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import Router from './components/Router/Router';
import { createContext, useEffect, useState } from 'react';
import ReactGA from "react-ga4";

import background from './assets/static/background.png'
import backgroundDesktop from './assets/static/background-desktop.png'
import ScrollToTop from './components/ScrollToTop';

export const Context = createContext();

const App = () => {

  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    window.addEventListener('resize', () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight })
    })
  }, [])

  const globalTheme = {
    error: '#d31717',
    primary: '#F18903',
    secondary: '#ffff',
    third: '#000000',
    gold: '#E5BC2B',
    grey: '#C2C2C2',
    hoverButton: '#DB6109',
    height: dimensions.height,
    width: dimensions.width,
    primaryFont: "'Montserrat', sans-serif",
    textPrimary: '#F18903',
    textSecondary: '#ffff',
    opacityFontLight: 'rgba(81, 81, 81, 0.5)',
    opacityFontDark: 'rgba(255, 255, 255, 0.65)',
    opacityFontDarker: 'rgba(255, 255, 255, 0.75)'
  }

  ReactGA.initialize("G-C1K8HW2H3J");

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <ThemeProvider theme={globalTheme}>
    <S.Background><img src={dimensions.width > 800 ? backgroundDesktop : background} alt='background' /></S.Background>
    <GlobalStyle />
    <ScrollToTop />
    <Router />
  </ThemeProvider>
}

export default App;

// 
// ───  STYLE DEFINITION ───────────────────────────────────────
//
const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    min-height: ${({ theme }) => theme.height}px;
    font-family: ${({ theme }) => theme.primaryFont};

    a {
      text-decoration: none;
    }

    p {
      margin: 0;
    }
  }

  .a2a_full_footer{
    display: none;
  }

  #root {
    min-height: ${({ theme }) => theme.height}px;
    display: flex;
    flex-direction: column;
  }
`;

// 
// ─── STYLE DEFINIITION ───────────────────────────────────────
//
const S = {}

S.Background = styled.div`
  position: fixed;
  inset: 0;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover
  }

  &:after {
    inset: 0px;
    content: "";
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.9) 100%);
  }
`