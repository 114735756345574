import { Outlet } from "react-router-dom";
import styled from 'styled-components/macro';
import { AuthContainer, Logo } from "./Style/StyledComponents";
import logo from '../assets/static/logo-satis.png'
import { useLocation } from "react-router-dom";


const LayoutAuth = () => {
  const location = useLocation();
  return (
    <AuthContainer>
      <Logo><img src={logo} alt='Logo' /></Logo>
      <S.Container path={location.pathname} url={location.pathname === '/connexion' || location.pathname === '/inscription' ? true : false}>
        <Outlet />
      </S.Container>
    </AuthContainer>
  )
}

export default LayoutAuth


// 
// ───  STYLE DEFINITION ───────────────────────────────────────
//
const S = {};

S.Container = styled.div`
height: ${({ path, theme }) => path === '/' ? '' : theme.height - 130}px;
max-height: ${({ path, theme }) => path === '/' ? '' : '550px'};
display: flex;
flex-direction: column;
justify-content: ${props => props.url ? 'space-evenly' : 'center'} ;
position: relative;
`