// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState } from "react";
import styled from "styled-components/macro";
import { v4 } from 'uuid'
// ** UTILS **
import { Form, Button, ImgLeft, ImgRight, AdminRewardsContainer, Close, BackButton } from "../../Style/StyledComponents";
import { getValue, prepareFormData } from "../../../contexts/Utils";
import Formfields from "../../Formfields";
import Api from "../../../contexts/Api";
import { useNotification } from "../../UseNotification";
//
// ** PNG **
import euro from '../../../assets/static/euro.png'
import { breakpoints } from "../../Style/StyledBreakpoints";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditReward = (opt) => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({})
  // INIT
  const existingReward = opt.reward || {}

  const { notification } = useNotification();

  // 
  // ─── HANDLE SUBMIT ───────────────────────────────────────
  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    let { fields, errors } = prepareFormData({ formId: 'rewardForm' });

    if (errors) return setErrors({ 'rewardForm': { ...errors } });
    else setErrors({});

    if (Object.keys(existingReward).length) fields['reward_id'] = existingReward.reward_id
    else fields['reward_id'] = v4()

    if (!Object.keys(existingReward).length) fields['label'] = opt.label

    const handleReward = await Api({
      data: { fields },
      endpoint: '/rewards',
      method: Object.keys(existingReward).length ? 'PUT' : 'POST'
    })

    if (handleReward.success) {
      opt.onUpdate(fields)
      return notification({ variant: 'success', message: handleReward.message })
    } else {
      notification({ variant: 'error', message: handleReward.message })
    }
  }

  // 
  // ─── REWARD FORMS ───────────────────────────────────────
  //
  const fieldsLeft = [
    {
      type: "text", component: "text", name: "label", label: 'Niveau', width: '100%', icon: `<svg width="19" height="7" viewBox="0 0 19 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.65 -4.76837e-07H7.25V7H5.92L2.43 2.75V7H0.83V-4.76837e-07H2.17L5.65 4.25V-4.76837e-07ZM8.90617 7V-4.76837e-07H10.5262V7H8.90617ZM17.2684 -4.76837e-07H18.8784L15.8484 7H14.2484L11.2284 -4.76837e-07H12.9784L15.1084 5L17.2684 -4.76837e-07Z" fill="white"/>
      </svg>`, required: true
    },
    { type: "text", component: "text", name: "points", label: 'Points nécessaires', width: '100%', icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/></svg>`, required: true },
  ]

  const fieldsRight = [
    {
      type: "text", component: "text", name: "reward", label: 'Récompense', width: '100%', icon: window.innerWidth < 800 && `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.60209 9.71271C8.70157 9.71271 9.62827 9.24862 10.3822 8.33702L12 9.92818C10.9634 11.2707 9.39267 12 7.47644 12C4.69633 12 2.49738 10.3425 1.77487 7.83978H0V6.44751H1.53927C1.52356 6.29834 1.52356 6.14917 1.52356 6C1.52356 5.85083 1.52356 5.70166 1.53927 5.55249H0V4.16022H1.77487C2.49738 1.65746 4.69633 0 7.47644 0C9.39267 0 10.9634 0.729281 12 2.05525L10.3822 3.64641C9.62827 2.75138 8.70157 2.28729 7.60209 2.28729C6.20419 2.28729 5.0733 3 4.49215 4.16022H8.46597V5.55249H4.09948C4.08377 5.70166 4.08377 5.85083 4.08377 6C4.08377 6.14917 4.08377 6.29834 4.09948 6.44751H8.46597V7.83978H4.49215C5.0733 9 6.20419 9.71271 7.60209 9.71271Z" fill="white"/>
    </svg>`, required: true
    },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <AdminRewardsContainer>
    <S.Close onClick={opt.onClose}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z" /></svg>
    </S.Close>
    <h2>{Object.keys(existingReward).length ? 'Modifier la récompense' : 'Ajouter une récompense'}</h2>
    <S.Form id="rewardForm">
      <S.FlexContainer>
        <div className="border">
          {fieldsLeft.map(field => {
            return <Formfields key={field.name} field={{ ...field, ...{ default: !Object.keys(existingReward).length && field.name === 'label' ? opt.label : existingReward[field.name] }, ...{ error: getValue(errors, ['rewardForm', field.name, 'message']) } }} />
          })}
        </div>
        <div className="rewardLevel">
          {fieldsRight.map(field => {
            return <Formfields key={field.name} field={{ ...field, ...{ default: !Object.keys(existingReward).length && field.name === 'label' ? opt.label : existingReward[field.name] }, ...{ error: getValue(errors, ['rewardForm', field.name, 'message']) } }} />
          })}
          {window.innerWidth > 800 && <div className="euro">
            <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M19.9565 23.96C22.7565 23.96 25.1165 22.84 27.0365 20.64L31.1565 24.48C28.5165 27.72 24.5165 29.48 19.6365 29.48C12.5565 29.48 6.95648 25.48 5.11648 19.44H0.596484V16.08H4.51648C4.47648 15.72 4.47648 15.36 4.47648 15C4.47648 14.64 4.47648 14.28 4.51648 13.92H0.596484V10.56H5.11648C6.95648 4.52 12.5565 0.52 19.6365 0.52C24.5165 0.52 28.5165 2.28 31.1565 5.48L27.0365 9.32C25.1165 7.16 22.7565 6.04 19.9565 6.04C16.3965 6.04 13.5165 7.76 12.0365 10.56H22.1565V13.92H11.0365C10.9965 14.28 10.9965 14.64 10.9965 15C10.9965 15.36 10.9965 15.72 11.0365 16.08H22.1565V19.44H12.0365C13.5165 22.24 16.3965 23.96 19.9565 23.96Z" fill="#F18903" />
            </svg>
          </div>}
        </div>
      </S.FlexContainer>
      <S.ButtonContainer>
        {Boolean(Object.keys(existingReward).length) && <S.DeleteButton className="deleteButton" onClick={(e) => { e.preventDefault(); opt.onDelete(existingReward.reward_id) }}>
          {window.innerWidth > 800 && 'Supprimer'}
          {window.innerWidth < 800 && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg>}
        </S.DeleteButton>}
        <Button onClick={handleSubmit}>Enregistrer</Button>
      </S.ButtonContainer>
    </S.Form>
    <ImgLeft src={euro} alt='euro left' />
    <ImgRight src={euro} alt='euro right' />
  </AdminRewardsContainer>
};

export default EditReward;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Close = styled(Close)`
border-radius: 0px 15px 0px 15px;
`

S.Form = styled(Form)`
  justify-content: center;

  & .formfield_container {
    margin-bottom: 0px;

    & label {
      color: ${({ theme }) => theme.third};
      font-size: 14px;
      font-weight: 600;
    }

    & input {
      color: ${({ theme }) => theme.third};
      border: none;
      background: #EBEBEB;

      &:focus, :not(&[value=""]) {
        outline: none;
      }
    }
  }
`

S.FlexContainer = styled.div`
display: flex;
width: 100%;
flex-direction: row;
${breakpoints("flex-direction", "", [
  { 800: "column" },
])};
gap: 20px;
${breakpoints("gap", "", [
  { 800: "0" },
])};
align-items: center;

& > div {
  width: 50%;
  ${breakpoints("width", "", [
  { 800: "100%" },
])};
}

& .border {
border-right: 1px solid #EBEBEB;
  ${breakpoints("border-right", "", [
  { 800: "none" },
])};
padding-right: 20px;
  ${breakpoints("padding-right", "", [
  { 800: "0" },
])};
  
& .formfield_container:first-of-type {
  margin-bottom: 15px;
}

& .formfield_container:last-of-type {
  ${breakpoints("margin-bottom", "px", [
  { 800: 15 },
])};
}
}

& .rewardLevel {
  display: flex;
  align-items: flex-end;
  gap: 10px;

  & .euro {
    height: 44px;
    display: flex;
    align-items: center;
  }
}
`

S.ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
  margin-top: 20px;

  & button {
    width: 40%;
    margin: 0 auto;
    height: 40px;
  }

  & svg {
    fill: ${({ theme }) => theme.secondary};
    width: 12px;
    height: 12px;
  }
`

S.DeleteButton = styled(BackButton)`
border: 2px solid ${({ theme }) => theme.primary};
color: ${({ theme }) => theme.primary};
font-size: 15px;
font-weight: bold;

& :hover {
  border: 2px solid rgba(255, 255, 255, 0.4);
  background: ${({ theme }) => theme.hoverButton};
}
`