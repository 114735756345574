
// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import styled from 'styled-components/macro';
import logo from '../assets/static/logo-satis.png'
import { Container, Logo, Spinner } from '../components/Style/StyledComponents'

const LoadingScreen = () => {
  return <S.Container>
    <S.Logo><img src={logo} alt='Logo' /></S.Logo>
    <Spinner />
  </S.Container>
}

export default LoadingScreen

// 
// ───  STYLE DEFINITION ───────────────────────────────────────
//
const S = {};
S.Container = styled(Container)`
margin: auto;
opacity: 1;
`

S.Logo = styled(Logo)`
margin: auto;
z-index: 1;
`