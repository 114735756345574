import React, { useEffect, useState } from 'react';
import { getValue, useOutsideClick } from '../contexts/Utils';
import styled from 'styled-components/macro';
import SvgComponent from './Style/SvgComponent';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const GeoSearch = (props) => {
  const [geo, setGeo] = useState([]);
  const [search, setSearch] = useState('');
  const [focusActive, setFocusActive] = useState(false);
  const [geoApi, setGeoApi] = useState({ address: '', cp: '', city: '' });
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(false);

  const geoSearchRef = useOutsideClick(() => {
    if (!selected) {
      setSearch('');
      setError(true);
      setSelected(false)
    }
  });

  const defaultValue = props.default || '';

  useEffect(() => {
    if (search !== defaultValue.geo) setSearch(defaultValue.geo);
  }, [defaultValue])

  useEffect(() => {
    const timer = setTimeout(
      () =>
        search &&
        fetch(`https://api-adresse.data.gouv.fr/search/?q=${search}`)
          .then((response) => response.json())
          .then((result) => {
            setError(false)
            if (getValue(result, ['features']).length) {
              const geoArray = [];
              result.features.forEach((value) => {
                const values = value.properties;

                let geoValues = {
                  label: values.label,
                  city: values.city,
                  cp: values.postcode,
                  address: values.type === 'municipality' ? '' : values.name,
                };

                geoArray.push(geoValues);
              });
              setGeo([...geoArray]);
            }
          }),
      200
    );
    return () => clearTimeout(timer);
  }, [search]);

  const handleChange = (e) => setSearch(e.target.value);

  // 
  // ─── HANDLE RESULT CLICK ───────────────────────────────────────
  //
  const handleResultClick = (res) => {
    setSearch(res.label);
    setGeoApi({ ...res });
    setSelected(true)
  }

  // 
  // ─── HANDLE SEARCH FOCUS ───────────────────────────────────────
  //
  const handleFocus = (val) => {
    if (!val) {
      setTimeout(() => {
        setFocusActive(val);
      }, 150);
      return
    }

    setFocusActive(val);
  }

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    geo && (
      <>
        <S.Search ref={geoSearchRef}>
          <S.TextField error={props.error ? true : false} icon={Boolean(props.icon)} iconWidth={props.iconWidth}>
            <div>
              {props.icon && <SvgComponent svgCode={props.icon} />}
              <input
                value={search || defaultValue.geo || ''}
                onChange={handleChange}
                type="text"
                name={props.name}
                id={props.name}
                required={props.required}
                placeholder={props.placeholder}
                onFocus={() => handleFocus(true)}
                onBlur={() => handleFocus(false)}
              />
              {props.label && <label htmlFor={props.name}>{props.label}</label>}
            </div>
            {props.error && <span>{props.error}</span>}
            {error && <span className='error'>Veuillez sélectionner une ville dans la liste</span>}
          </S.TextField>
          {focusActive && <S.Results>
            {geo.map(e => <div key={e.label}><S.Result onClick={() => handleResultClick(e)}>
              {e.label}
            </S.Result></div>)}
          </S.Results>}
        </S.Search>
        <input
          type="hidden"
          name={`address`}
          value={geoApi.address || defaultValue.address}
        />
        <input
          type="hidden"
          name={`cp`}
          value={geoApi.cp || defaultValue.cp}
        />
        <input
          type="hidden"
          name={`city`}
          value={geoApi.city || defaultValue.city}
        />
      </>
    )
  );
};

export default GeoSearch;

const S = {}
S.TextField = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  & > div {
    position: relative;
    display: flex;
    align-items: center;

    & label {
      cursor: text;
      position: absolute;
      left: 10px;
      left: ${({ icon }) => icon ? '38px' : '10px'};
      font-size: 14px;
      transition: .3s;
      color: ${({ theme }) => theme.textSecondary};
    }
    & input {
      height: 44px;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 15px;
      border: none;
      width: 100%;
      box-sizing: border-box;
      background: #252525;
      border-radius: 0px;
      color: ${({ theme }) => theme.secondary};
      opacity: 0.7;
      font-family: ${({ theme }) => theme.primaryFont};
      
      &:focus, :not(&[value=""]) {

        & + label {
          transform: translateY(-24px);
          color: ${({ theme }) => theme.secondary};
          left: 0px;
        }
      }
    }
  }
  & span {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }

  & .error {
    color: ${({ theme }) => theme.error};
    margin-top: 5px;
    font-size: 13px;
  }
`

S.Search = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  position: relative;
`

S.Results = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  background: ${({ theme }) => theme.textSecondary};
  width: 100%;
  z-index: 100;
  background: ${({ theme }) => theme.textSecondary};
  max-height: 230px;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
  }
`

S.Result = styled.div`
  padding: 5px 10px;
  transition: .3s;
  cursor: pointer;
  max-width: 350px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;

  &:hover {
    background: #c6c7d2;
  }
  &:first-child {
    padding-top: 10px;
  }
  &:last-child {
    padding-bottom: 10px;
  }
`