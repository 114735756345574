import { useState } from 'react';
import styled from 'styled-components';

const Question = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return <S.Container className="question">
    <S.Question onClick={toggle} $isOpen={isOpen} >
      {question}
      <S.Chevron className="chevron">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <path d="M7 10.5L14 17.5L21 10.5" stroke="#7b7a6a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </S.Chevron>
    </S.Question>
    <S.Answer $isOpen={isOpen}>
      <div dangerouslySetInnerHTML={{ __html: answer }} />
    </S.Answer>
  </S.Container>
}

export default Question;

const S = {};

S.Container = styled.div`
  align-self: stretch;
  flex: 1;
  padding: 12px 0;
`

S.Question = styled.button`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;
  width: 100%;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 1.3;
  color: white;
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;

  & > .chevron {
    transform: rotateX(${({ $isOpen }) => $isOpen ? '180deg' : '0deg'});
  }
`

S.Chevron = styled.div`
  padding-top: 5px;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
`

S.Answer = styled.p`
  display: grid;
  grid-template-rows: ${({ $isOpen }) => $isOpen ? '1fr' : '0fr'};
  overflow: hidden;
  transition: grid-template-rows 0.3s ease-in-out;
  color: white;
  font-size: 18px;
  line-height: 1.5;
  will-change: grid-template-rows;
  margin-top: ${({ $isOpen }) => $isOpen ? '20px' : '0'};

  & div {
    overflow: hidden;
  }
`