// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState } from "react";
import styled from "styled-components/macro";
import { v4 } from 'uuid'
// ** UTILS **
import { ModalWrapper, ModalContent, ModalOverlay, Form, Button, Close } from "../../Style/StyledComponents";
import { getValue, prepareFormData } from "../../../contexts/Utils";
import Formfields from "../../Formfields";
import Api from "../../../contexts/Api";
import { useNotification } from "../../UseNotification";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditUser = (opt) => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({})
  // INIT
  const existingUser = opt.user || {}

  const { notification } = useNotification();

  // 
  // ─── HANDLE CHANGE INFOS ───────────────────────────────────────
  //
  const handleChangeInfos = async (e, id) => {
    e.preventDefault()

    let data = prepareFormData({ formId: id })

    if (data.errors) {
      setErrors({ ...errors, [id]: { ...data.errors } })
    } else {
      if (Object.keys(errors).length) {
        setErrors({});
      }

      if (data.fields.job_ids) {
        data.fields.old_jobs = existingUser.job_ids
      }

      //
      // ─── API REQUEST ───────────────────────────────────────
      //
      const manageUser = await Api({
        data: { fields: data.fields, single_user: existingUser.user_id },
        method: 'PUT',
        endpoint: '/users',
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageUser.success) {
        data.fields['user_id'] = existingUser.user_id
        if (data.fields.old_jobs) delete data.fields.old_jobs
        opt.onUpdate(data.fields)
        return notification({ variant: 'success', message: manageUser.message })
      } else {
        notification({ variant: 'error', message: manageUser.message })
      }
    }
  }

  // 
  // ─── HANDLE CHANGE PASSWORD ───────────────────────────────────────
  //
  const handleChangePassword = async (e, id) => {
    e.preventDefault()

    let data = prepareFormData({ formId: id })

    if (data.errors) {
      setErrors({ ...errors, [id]: { ...data.errors } })
    } else {
      if (Object.keys(errors).length) {
        setErrors({});
      }
      delete data.fields.confirmpassword
      //
      // ─── API REQUEST ───────────────────────────────────────
      //
      const manageUserPassword = await Api({
        data: { fields: data.fields, single_user: existingUser.user_id },
        method: 'PUT',
        endpoint: '/users/password',
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageUserPassword.success) {
        document.getElementById(id).reset();
        return notification({ variant: 'success', message: manageUserPassword.message })
      } else {
        notification({ variant: 'error', message: manageUserPassword.message })
      }
    }
  }

  // 
  // ─── ACCOUNT FORMS ───────────────────────────────────────
  //
  const account = [
    {
      title: 'Informations générales',
      id: 'account_infos',
      fields: [
        { type: "text", component: "text", name: "firstname", label: 'Prénom', width: '49%', required: true },
        { type: "text", component: "text", name: "name", label: 'Nom', width: '49%', required: true },
        { type: "email", component: "text", name: "email", label: 'Email', width: window.innerWidth > 800 ? '49%' : '100%', required: true },
        { type: "tel", component: "text", name: "phone", label: 'Téléphone', width: window.innerWidth > 800 ? '49%' : '100%', required: true },
        { type: "text", component: "text", name: "address", label: 'Adresse', width: window.innerWidth > 800 ? '49%' : '100%', required: true },
        { type: "text", component: "text", name: "cp", label: 'Code postal', width: '49%', required: true },
        { type: "text", component: "text", name: "city", label: 'Ville', width: '49%', required: true },
        { type: "select", component: "search_select_multiple_jobs", name: "job_ids", label: 'Métier(s) recherché(s)', width: window.innerWidth > 800 ? '49%' : '100%', required: true }
      ],
      handle: handleChangeInfos
    },
    {
      title: 'Sécurité',
      id: 'change_password',
      fields: [
        { type: "password", component: "text", name: "password", label: 'Nouveau mot de passe', width: window.innerWidth > 800 ? '49%' : '100%', required: true },
        { type: "password", component: "text", name: "confirmpassword", label: 'Confirmation du nouveau mot de passe', width: window.innerWidth > 800 ? '49%' : '100%', required: true }
      ],
      handle: handleChangePassword
    },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <ModalWrapper>
    <ModalOverlay onClick={opt.onClose} />
    <S.ModalContent>
      <Close onClick={opt.onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z" /></svg>
      </Close>
      {account.map((section, index) => {
        return <S.Form key={index} id={section.id}>
          <h2>{section.title}</h2>
          {section.fields.map(field => {
            return <Formfields key={field.name} field={{ ...field, ...{ default: existingUser[field.name] }, ...{ error: getValue(errors, [section.id, field.name, 'message']) } }} />
          })}
          <Button as="input" type="submit" value="Enregistrer" onClick={(e) => section.handle(e, section.id)}></Button>
        </S.Form>
      })}
    </S.ModalContent>
  </ModalWrapper>
};

export default EditUser;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.ModalContent = styled(ModalContent)`
overflow-y: auto;
`

S.Form = styled(Form)`
align-items: flex-start;

  & .formfield_container {
    & label {
      color: ${({ theme }) => theme.third};
    }

    & input, & select {
      color: ${({ theme }) => theme.third};
      border: 1px solid ${({ theme }) => theme.opacityFontLight};

      &:focus, :not(&[value=""]) {
        border: 1px solid ${({ theme }) => theme.primary};
        outline: none;
      }
    }
  }

  & .dropdown-jobs {
    & .job-label {
      color: ${({ theme }) => theme.third};
    }
  }
  & .selectedItem {
    color: ${({ theme }) => theme.third};
  }
`