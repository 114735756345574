import { useState } from "react";
import styled from "styled-components/macro";
import Formfields from "../Formfields";
import { Button } from "../Style/StyledComponents";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const SearchBar = (opt) => {
  const defaults = opt.defaults || {};
  const defaultSearch = opt.defaultSearch || '';

  const [showFilters, setShowFilters] = useState(false);

  const radius = [
    { id: 0, label: 'Pas de rayon' },
    { id: 5, label: '5 km' },
    { id: 10, label: '10 km' },
    { id: 20, label: '20 km' },
    { id: 50, label: '50 km' },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.SearchBar className="searchBar">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z" /></svg>
      <Formfields field={{ type: "text", component: "text", name: "title", placeholder: 'Rechercher ...', width: '100%', handleChange: opt.change, handleSearch: opt.search, default: opt.filter ? defaults.get('title') : defaultSearch }} />
      {!opt.disabled && <S.Touch onClick={() => setShowFilters(!showFilters)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM64 256c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H96c-17.7 0-32-14.3-32-32zM288 416c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32h64c17.7 0 32 14.3 32 32z" /></svg>
      </S.Touch>}
    </S.SearchBar>
    {showFilters && <S.Filter id="searchBar">
      <S.Form className="left">
        <h3>Ville</h3>
        {/* <Formfields field={{ type: "text", component: "text", name: 'city', handleSearch: opt.search, default: opt.filter && defaults.get('city'), width: '100%' }} /> */}
        <Formfields field={{ type: "text", component: "search_cities", name: 'city', handleSearch: opt.search, default: opt.filter && defaults.get('label'), width: '100%' }} />
        <Formfields field={{ type: "select", component: "select", name: 'radius', placeholder: 'Rayon', handleSearch: opt.search, data: radius, fromSearch: true, city: opt.filter && defaults.get('zip_code'), default: opt.filter && defaults.get('radius') ? defaults.get('radius') : -1, width: '100%' }} />
      </S.Form>
      <S.Form>
        {Object.entries(opt.filters).map(([key, filter]) => {
          return <>
            <h3>{key === 'contract_type' ? 'Type de contrat' : key}</h3>
            <S.Flex>
              {filter && filter.map((item, key) => <Formfields key={key} field={{ type: "text", component: "checkbox", name: `contract_type_${item.toLowerCase()}`, checked: opt.filter && defaults.get(`contract_type_${item.toLowerCase()}`), handleSearch: opt.search, label: item, width: '100%' }} />)}
            </S.Flex>
          </>
        })}
      </S.Form>
    </S.Filter>}
  </S.Container>
};

export default SearchBar;

const S = {}
S.Container = styled.div`
margin: 20px 0;
`

S.SearchBar = styled.div`
display: flex;
background: ${({ theme }) => theme.secondary};
align-items: center;
padding: 10px 20px;
border-radius: 30px;
max-width: 350px;

  & svg {
    fill: #000000;
    width: 15px;
    height: 15px;
  }

  & input {
    color: #000000;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    font-size: 14px;
    border: none;
    outline: none;

    &::placeholder {
      color: #000000
    }
  }
`

S.Reset = styled.div`
display: flex;
justify-content: center;
align-items: center;
background: ${({ theme }) => theme.primary};
border-radius: 50%;
padding: 7px;
margin-right: 10px;

& svg {
  width: 15px;
  height: 15px;
  fill: ${({ theme }) => theme.secondary};
}
`

S.Touch = styled.div`
height: 100%;
width: 20%;
display: flex;
justify-content: flex-end;
align-items: center;
`

S.Filter = styled.form`
display: flex;
justify-content: space-around;
align-items: baseline;
flex-wrap: wrap;
margin: 10px ${({ theme }) => theme.width > 800 ? '10px' : 'auto'};
padding: 15px 20px;
border-radius: 20px;
background: ${({ theme }) => theme.secondary};
${({ theme }) => theme.width > 800 && 'max-width: 350px'};
gap: 20px;

& h3 {
  font-size: 14px;
  margin-top: 0;
}

& .left {
  flex:1;
}
`

S.Form = styled.div`

& select {
  color: ${({ theme }) => theme.third};
  height: 35px;
  border-top: 1px solid #F4F4F4;
  border-bottom: 1px solid #F4F4F4;
  border-right: 1px solid #F4F4F4;
}

& input[type="text"] {
  background: #F4F4F4;
  border-left: 3px solid #F18903;
  border-radius: 2px;
  height: 28px;
  max-width: 190px;
  color: ${({ theme }) => theme.third};
}

& input[type="text"] {
  background: #F4F4F4;
  border-left: 3px solid #F18903;
  border-radius: 2px;
  height: 28px;
  max-width: 190px;
  color: ${({ theme }) => theme.third};
}

& fieldset.MuiOutlinedInput-notchedOutline {
  display: none;
}

& .MuiAutocomplete-inputRoot{
  padding-left: 0!important;
}

& .MuiAutocomplete-clearIndicator {
  visibility: visible;
}
`

S.Flex = styled.div`
display: flex;
flex-direction: column;
gap: 10px;

& label {
  font-size: 13px;
  color: ${({ theme }) => theme.third};
}

& input[type="checkbox"] {
  width: 15px;
  height: 15px
}
`

S.Button = styled(Button)`
  font-size: 15px;
  width: 100%;
  margin: 10px auto;
  padding: 8px;
  gap: 15px;

  & svg {
    width: 20px;
    height: 20px;
    fill: white
  }
`