// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
//
// ** COMPONENTS **
import Sponsor from "../components/Pop-up/Sponsor";
import { Container } from "../components/Style/StyledComponents";
import List from "./Offers/List";
import Rewards from "./Rewards";
import Carousel from "../components/Carousel";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Home = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [showPopover, setShowPopover] = useState(false);

  const handlePopoverClose = () => {
    setShowPopover(false);
    localStorage.setItem('popoverClosed', 'true');
  };

  useEffect(() => {
    const popoverClosed = localStorage.getItem('popoverClosed');
    if (popoverClosed === 'false') {
      setShowPopover(true);
    }
  }, []);

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <Carousel />
    {/* PARRAINAGE POP UP */}
    {showPopover && <Sponsor close={handlePopoverClose} />}
    {/* REWARD BAR */}
    {!showPopover && <Rewards noRewards={true} />}
    {/* OFFERS LIST */}
    <List />
  </S.Container>
};

export default Home;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled(Container)`
& .rewards {
  padding: 0
}
`