import { NavLink, Outlet } from "react-router-dom";
import Navigation from "./Navigation";
import styled from 'styled-components/macro';
import { BackButton, Logo } from "./Style/StyledComponents";
import logo from '../assets/static/logo-satis.png'
import { breakpoints } from "./Style/StyledBreakpoints";
//import { Logout } from "../contexts/Utils";


const Layout = () => {
  return (
    <S.Main>
      <S.Header>
        <Logo to={'/'}><img src={logo} alt='Logo' /></Logo>
        {/* {window.innerWidth < 600 && <S.BackButton onClick={Logout}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" /><path d="M9 12h12l-3 -3" /><path d="M18 15l3 -3" /></svg></S.BackButton>} */}
        <Navigation />
      </S.Header>
      <Outlet />
      <S.Footer>
        <div>
          <span>© 2023 Satis</span>
          <NavLink to={'/mentions-legales'}>Mentions légales</NavLink>
          <NavLink to={'/politique-confidentialite'}>Politique de confidentialité</NavLink>
        </div>
      </S.Footer>
    </S.Main>
  )
}

export default Layout


// 
// ───  STYLE DEFINITION ───────────────────────────────────────
//
const S = {};
S.Main = styled.main`
z-index: 1;
${({ theme }) => theme.width < 900 && 'padding-bottom: 100px'};
min-height: ${({ theme }) => theme.width < 900 ? (theme.height - 100) : theme.height}px;
display: flex;
flex-direction: column;
`

S.Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  ${breakpoints("flex-direction", "", [
  { 900: "row!important" },
  { 800: "column" },
], "min-width")};
  position: sticky;
  align-items: center;
  padding: 30px 0;
  z-index: 1;
`

S.BackButton = styled(BackButton)`
  position: absolute;
  right: 20px;
  width: fit-content;
  padding: 10px;
`

S.Footer = styled.footer`
  width: 100%;
  padding: 60px 0 20px 0;
  margin-top: auto;
  
  & div {
    align-items: center;
    display: flex;  
    margin: 0 auto;
    width: fit-content;
    gap: ${({ theme }) => theme.width > 800 ? '40' : '10'}px;
    color: white;
    flex-wrap: ${({ theme }) => theme.width > 800 ? 'nowrap' : 'wrap'};
    font-size: ${({ theme }) => theme.width > 800 ? '15' : '10'}px;
  }

  & a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.width > 800 ? '15' : '10'}px;
    font-weight: ${({ theme }) => theme.width > 800 ? '700' : '500'};
    position: relative;
    gap: 2px;
    color: ${({ theme }) => theme.textSecondary};
    cursor: pointer;
  }

  & a.active:after {
    content: "";
    position: absolute;
    bottom: -6px;
    width: 100%;
    height: 2px;
    border-radius: 0 0 10px 10px;
    background: ${({ theme }) => theme.width > 800 ? theme.primary : theme.secondary};
  }
`